import React, { useEffect, useState } from "react";
import CollapsibleCardComponent from "./CollapsibleCardComponent";
import ItemModifierForm from "./forms/ItemModifierForm";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ListGroup } from "reactstrap";
import ViewHelper from "../../helpers/ViewHelper";
import CONSTANTS from "../../constants";

const MenuModifiersList = (props) => {
  const getItemsForView = (items) => {
    return items.map((item) => ({
      title: item.Option.option_name,
      id: item.menu_option_id,
      ...item,
    }));
  };

  const {
    modifiers,
    updateModifiers,
    showConfirmationDialog,
    toggleGlobalModifierModal,
    isDragDisabled: dragDisableProp,
    location: location,
  } = props;

  const [items, setItems] = useState(getItemsForView(modifiers));
  const [activeId, setActiveId] = useState(0);

  useEffect(() => {
    setItems(getItemsForView(modifiers));
  }, [props]);

  const updateItem = (item) => {
    let itemsCopy = items.map((i) => (i.id === item.id ? item : i));
    updateModifiers(itemsCopy);
  };

  const toggleCollapse = (id) => {
    if (id === activeId) setActiveId(0);
    else setActiveId(id);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const itemsCopy = ViewHelper.reorder(
      items,
      result.source.index,
      result.destination.index,
      true
    );
    setItems(itemsCopy);
    updateModifiers(itemsCopy);
  };

  const deleteItem = (item) => {
    showConfirmationDialog(
      () => {
        let itemsCopy = items.filter((i) => i.id !== item.id);
        updateModifiers(itemsCopy);
      },
      null,
      "Are you sure you want to remove this modifier from this item?"
    );
  };

  const options = {
    radio: "Single",
    select: "Selector",
    checkbox: "Checkbox",
  };
  const menuType = [
    {
      value: "kitchen",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.KITCHEN,
    },
    {
      value: "bar",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.BAR,
    },
    {
      value: "dessert",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.DESSERT,
    },
  ];
  return (
    <ListGroup id="list-group-dnd">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              <div className="mb-1">
                {items &&
                  items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={index}
                      isDragDisabled={false}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag-wrapper"
                        >
                          <CollapsibleCardComponent
                            deleteCardItem={deleteItem.bind(this)}
                            badgeTitle={options[item.Option.display_type]}
                            bodyColor="content-dark-bg-color"
                            item={item}
                            toggleCollapse={toggleCollapse.bind(this)}
                            isOpen={item.id === activeId}
                          >
                            <ItemModifierForm
                              showConfirmationDialog={showConfirmationDialog}
                              item={item}
                              updateModifier={updateItem.bind(this)}
                              location_id={location.loc_id}
                              menuType={menuType}
                            />
                          </CollapsibleCardComponent>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {items && items.length === 0 ? (
                  <span>No Variants attached to this Item.</span>
                ) : null}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ListGroup>
  );
};

export default MenuModifiersList;
