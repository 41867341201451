import CONSTANTS from '../../../constants';
import AnalyticsService from '../../../services/AnalyticsService';

export function getOverallStats(loc_id, filters, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.OVERALL,  data: null, loading: true });
      let stats = await AnalyticsService.getOverallStats(loc_id, filters);
      dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.OVERALL, data: stats, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.OVERALL,  data: null, loading: false });
    }
  };
}

export function getCustomersStats(loc_id, filters, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.CUSTOMERS, data: null, loading: true });
      let stats = await AnalyticsService.getCustomerStats(loc_id, filters);
      dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.CUSTOMERS, data: stats, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.CUSTOMERS,  data: null, loading: false });
    }
  };
}

export function getItemsStats(loc_id, filters, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.ITEMS, data: null, loading: true });
      let stats = await AnalyticsService.getItemStats(loc_id, filters);
      dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.ITEMS, data: stats, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.ITEMS,  data: null, loading: false });
    }
  };
}

export function getDashboardStats(loc_id, role , initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.DASHBOARD, data: null, loading: true });
      let stats = await AnalyticsService.getDashboardStats(loc_id , role);
      dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.DASHBOARD, data: stats, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.ANALYTICS.DASHBOARD,  data: null, loading: false });
    }
  };
}