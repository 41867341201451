import React from "react";
import DataTable from "react-data-table-component";
import { Check, Edit2 } from "react-feather";
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import ImageHelper from "../../helpers/ImageHelper";

const ListAllLocation = (props) => {
  const editLocation = (id) => {
    props.editLocation(id);
  };

  const renderLocations = (locations) => {
    console.log("locations", locations);
    if (!locations || locations.length === 0)
      return <h1>No App Locations found.</h1>;

    return (
      <>
        <Col sm="12">
          <Row>
            {locations.map((location) => {
              const image = ImageHelper.getImageURL(location.img);
              return (
                <Col lg="3" md="6" sm="12" key={location.loc_id}>
                  <Card>
                    <CardHeader className="mx-auto flex-column">
                      <h4>{location.name}</h4>
                      <p>{location.category_name}</p>
                    </CardHeader>
                    <CardBody className="text-center pt-0">
                      <div className="avatar mr-1 avatar-xl">
                        <img src={image} alt="avatarImg" />
                      </div>
                      <div className="d-flex justify-content-center">
                        <Button.Ripple
                          className="btn-block gradient-light-primary mt-2"
                          onClick={() => editLocation(location.loc_id)}
                        >
                          Edit
                        </Button.Ripple>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>
      </>
    );
  };
  return renderLocations(props.locations);
};

export default ListAllLocation;
