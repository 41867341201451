import React, { Component } from "react";
import { Link } from "react-router-dom";
import SelectLocation from "../shared/SelectLocation";

import CONSTANTS from "../../constants";


export default class Locations extends Component {
  onSelect(location) {
    this.props.history.push(
      `${CONSTANTS.BASE_URL}locations/${location.loc_id}`
    );
  }

  render() {
    return (
      <>
        <div className="locations">
          <SelectLocation hideBack={true} onSelect={this.onSelect.bind(this)} />
          <div className="add-location-button p-3">
            {/* TODO: Add Validation so only admins can see that */}
            <Link
              className="col-sm-12 col-md-6 col-lg-3 btn btn-primary float-right"
              to={`${CONSTANTS.BASE_URL}locations/new`}
            >
              Add New Location
            </Link>
          </div>
        </div>
      </>
    );
  }
}
