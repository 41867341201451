import { combineReducers } from 'redux'
import { options, option } from "./AllOptionReducer";


const optionsReducer = combineReducers({
  options,
  option
});

export default optionsReducer;
