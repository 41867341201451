import { combineReducers } from 'redux'
import { categories } from './CategoriesReducer';
import { currencies } from './CurrenciesReducer';
import { statuses } from './StatusesReducer';


const configurationsReducer = combineReducers({
  categories,
  currencies,
  statuses
});

export default configurationsReducer;
