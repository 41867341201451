import React from "react";
import { Card, CardHeader, CardTitle, CardBody, Button } from "reactstrap";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { Plus, X } from "react-feather";

import LocationService from "../../services/LocationService";
import BannersService from "../../services/BannersService";
import BannerModal from "../shared/forms/BannerModel";
import ImageHelper from "../../helpers/ImageHelper";
import ConfirmationModal from "../shared/forms/ConfirmationModal";

class Banners extends React.Component {
  state = {
    bannerlist: [],
    loading: true,
    isBannerModalOpen: false,
    selectedBanner: null,
    location: null,
    confirmationDialog: {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    },
  };
  async componentDidMount() {
    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false, location: location });
    } else {
      this.setState({ loading: false });
    }
  }

  async refreshData(loc_id, initialize = true) {
    this.setState({ loading: initialize });
    let banners = await BannersService.getAllBanners(loc_id);
    this.setState({ bannerlist: banners });
  }

  async toggleBannerModal(banner = null) {
    let {
      state: { isBannerModalOpen },
    } = this;
    const selectedBanner = banner;
    isBannerModalOpen = !isBannerModalOpen;
    return this.setState({ selectedBanner, isBannerModalOpen });
  }

  async deleteBanner(banner) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        this.setState({ confirmationDialog: defaultState, loading: true });
        let bannerlist = await BannersService.deleteBanner(
          this.state.location.loc_id,
          banner.banner_id
        );
        this.setState({ bannerlist: bannerlist, loading: false });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState, loading: false });
      },
    };
    this.setState({ confirmationDialog });
  }

  async saveBanner() {
    const fileInput = document.querySelector("#bannerFileInput");
    const formData = new FormData();
    if (fileInput.files.length > 0) {
      formData.append("file", fileInput.files[0]);
      let banner = await BannersService.saveBanner(
        this.state.location.loc_id,
        formData
      );
      await this.toggleBannerModal();
      this.setState({ bannerlist: banner, loading: false });
    }
  }

  render() {
    if (this.state.loading) return <SpinnerComponent />;
    return (
      <>
        <div className="location">
          <div className="display-flex flex-row justify-content-between mb-1">
            <div className="d-flex">
              <Button
                outline
                className="font-weight-bold custom-padding primary-2-border mb-1 ml-1"
                color={"primary"}
                size="md"
                onClick={() => this.toggleBannerModal()}
              >
                <Plus className="mx-2" size={20} />{" "}
                <span className="ml-1 mr-5 primary">Add New Banner</span>
              </Button>
            </div>
          </div>
          {this.state.bannerlist.length > 0 ? (
            <div className="row">
              <div className="col-12">
                <Card>
                  <CardHeader>
                    <CardTitle>{this.state.location.name}'s Banners</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      {this.state.bannerlist.map((banner) => {
                        return (
                          <div
                            className="col-md-4 col-sm-6 col-lg-3 mb-5"
                            style={{ border: "1px solid #e6e7e9" }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: -10,
                                right: 0,
                              }}
                            >
                              <Button.Ripple
                                style={{ width: 20, height: 20 }}
                                onClick={() => this.deleteBanner(banner)}
                                className="m-0 p-0"
                                color="danger"
                              >
                                <X size={14} />
                              </Button.Ripple>
                            </div>
                            <img
                              src={ImageHelper.getImageURL(banner.image)}
                              alt="swiper 1"
                              className="img-fluid"
                              style={{ minHeight: "200px", maxHeight: "200px" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <Card>
                  <CardHeader>
                    <CardTitle>{this.state.location.name}'s Banners</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      <div className="col-12">No banner added.</div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          )}
          <BannerModal
            deleteBanner={(banner) => this.deleteBanner(banner)}
            saveBanner={() => this.saveBanner()}
            isOpen={this.state.isBannerModalOpen}
            toggleModal={(banner) => this.toggleBannerModal(banner)}
          />
          <ConfirmationModal
            isOpen={this.state.confirmationDialog.show}
            accept={this.state.confirmationDialog.acceptCallback.bind(this)}
            reject={this.state.confirmationDialog.rejectCallback.bind(this)}
          />
        </div>
      </>
    );
  }
}
export default Banners;
