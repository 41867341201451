import CONSTANTS from '../../../constants'
import LocationService from '../../../services/LocationService';
import OrderService from '../../../services/OrderService';

export function getLocation(locationId) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      let location = await LocationService.getLocationFromServer(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function getAllLocations() {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      let location = await LocationService.getAllLocations();
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function getAllLocationsByStaffId(staffid) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      let location = await LocationService.getAllLocationsByStaffId(staffid);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}


export function updateLocation(locationData) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      let location = await LocationService.updateLocationFormData(locationData);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function createLocation(locationData) {
  return async dispatch => {
    try {
      let location = await LocationService.createLocation(locationData);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: location, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function initializeNewLocation() {
  return async dispatch => {
    dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: {}, loading: false });
  };
}
