import React from "react";
import Select from "react-select";
import { Button, Row, Col, Card, CardBody } from "reactstrap";
import "../../assets/scss/plugins/forms/react-select/_react-select.scss";
import "../../assets/scss/pages/app-ecommerce-shop.scss";
import { X } from "react-feather";
import FREETABLE from "../../assets/img/pages/table_empty.png";
import BOOKEDTABLE from "../../assets/img/pages/table_booked.png";

class ListSessionTablesStatus extends React.Component {
  state = {
    inCart: [],
    inWishlist: [],
    view: "grid-view",
  };

  renderTablesData = (tables) => {
    let renderTables = tables.map((table, i) => {
      console.log(table);
      const sessionTime = new Date(
        table?.LocationSessions[0]?.session_date +
          " " +
          table?.LocationSessions[0]?.start_time
      );
      return (
        <div className="col-lg-3 col-md-4 col-sm-6">
          <Card className="ecommerce-card table-session" key={i}>
            <div className="card-content">
              <div className="row">
                <div className="col-12">
                  <span className="float-left">
                    {table.is_table_available
                      ? "Time: 00"
                      : `Time: ${this.getSessionTime(sessionTime)}`}
                  </span>
                  <span className="float-right">
                    Total:{" "}
                    {table.is_table_available
                      ? 0
                      : table?.LocationSessions[0]?.Orders.length > 0
                      ? this.props.location.currency +
                        " " +
                        table?.LocationSessions[0]?.Orders[0].session_order_amount
                      : 0}
                  </span>
                </div>
              </div>
              <div className="item-img text-center">
                {table.is_table_available ? (
                  <img
                    className="img-fluid"
                    src={FREETABLE}
                    alt={table.table_name}
                  />
                ) : (
                  <img
                    className="img-fluid"
                    src={BOOKEDTABLE}
                    alt={table.table_name}
                  />
                )}

                <h4 className="col-12">
                  {table.table_name}{" "}
                  {table.is_table_available ? (
                    <span className="success">Free</span>
                  ) : (
                    <span
                      className="cart bg-warning rounded"
                      onClick={() =>
                        this.props.closeLocationSession(
                          table?.LocationSessions[0]?.session_id
                        )
                      }
                    >
                      Close Session
                    </span>
                  )}
                </h4>
              </div>
              <CardBody className="text-center">
                <div className="item-wrapper">
                  <div className="product-price">
                    <h4 className="item-price">
                      Session Started:{" "}
                      {table.is_table_available ? (
                        <span className="success">None</span>
                      ) : (
                        <span className="warning">
                          {sessionTime.toLocaleTimeString("en-US", {
                            // en-US can be set to 'default' to use user's browser settings
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      )}
                    </h4>
                  </div>
                </div>
              </CardBody>
            </div>
          </Card>
        </div>
      );
    });
    return renderTables;
  };

  getSessionTime = (time_diff) => {
    let cDate = new Date();
    let sDate = new Date(time_diff);
    const diffTime = Math.abs(cDate - sDate);
    let diffDays = Math.ceil(diffTime / (1000 * 60));
    diffDays = diffDays + " Mins";
    return diffDays;
  };

  render() {
    const { tables } = this.props;

    return (
      <div className="col-12">
        <Row>
          <Col sm="12">
            <div id="ecommerce-products">
              <Row>{this.renderTablesData(tables?.data)}</Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ListSessionTablesStatus;
