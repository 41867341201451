import React from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Icon from "react-feather";
import classnames from "classnames";
import CONSTANTS from "../../../constants";

const handleNavigation = (e, path) => {
  e.preventDefault();
  // history.push(path)
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: [],
    countNotification: 0,
  };

  componentDidMount() {}

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  sessionDetail(sessionid, notification_id) {
    this.props.sessionDetail(sessionid, notification_id);
  }

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown });

  render() {
    let { navBarNotifications } = this.props;
    let navBarNotificationsList = navBarNotifications?.notificationList
      ? navBarNotifications?.notificationList
      : null;
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          {/* <DropdownToggle tag="a" className="nav-link position-relative">
            <Icon.ShoppingCart size={21} />
            {this.state.shoppingCart.length > 0 ? (
              <Badge pill color="primary" className="badge-up">
                {this.state.shoppingCart.length}
              </Badge>
            ) : null}
          </DropdownToggle> */}
          {/* <DropdownMenu
            tag="ul"
            right
            className={`dropdown-menu-media dropdown-cart ${
              this.state.shoppingCart.length === 0 ? "empty-cart" : ""
            }`}
          >
            <li
              className={`dropdown-menu-header ${
                this.state.shoppingCart.length === 0 ? "d-none" : "d-block"
              }`}
            >
              <div className="dropdown-header m-0">
                <h3 className="white">
                  {this.state.shoppingCart.length} Items
                </h3>
                <span className="notification-title">In Your Cart</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {renderCartItems}
            </PerfectScrollbar>
            <li
              className={`dropdown-menu-footer border-top ${
                this.state.shoppingCart.length === 0 ? "d-none" : "d-block"
              }`}
            >
              <div
                className="dropdown-item p-1 text-center text-primary"
                // onClick={() => history.push("/ecommerce/checkout")}
              >
                <Icon.ShoppingCart size={15} />
                <span className="align-middle text-bold-600 ml-50">
                  Checkout
                </span>
              </div>
            </li>
            <li
              className={`empty-cart ${
                this.state.shoppingCart.length > 0 ? "d-none" : "d-block"
              } p-2`}
            >
              Your Cart Is Empty
            </li>
          </DropdownMenu> */}
        </UncontrolledDropdown>
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            <Badge pill color="primary" className="badge-up">
              {" "}
              {navBarNotifications.length === 0 ? (
                <Icon.Loader size={25} />
              ) : (
                navBarNotifications.countNotification
              )}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                {navBarNotifications.length === 0 ? (
                  <>
                    <h3 className="text-white">
                      Please wait notifications are loading
                    </h3>
                    <span className="notification-title">
                      App Notifications
                    </span>
                  </>
                ) : (
                  <>
                    <h3 className="text-white">
                      {navBarNotifications.countNotification} New
                    </h3>
                    <span className="notification-title">
                      App Notifications
                    </span>
                  </>
                )}
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {navBarNotifications.length !== 0 ? (
                <>
                  {navBarNotifications.notificationList.length > 0 ? (
                    <>
                      {navBarNotificationsList.map((notification) => {
                        return (
                          <>
                            {notification.status === "unseen" && (
                              <div
                                className="d-flex justify-content-between"
                                onClick={this.sessionDetail.bind(
                                  this,
                                  notification.session_id,
                                  notification.not_id
                                )}
                              >
                                <Media className="d-flex align-items-start">
                                  <Media left href="#">
                                    <Icon.PlusSquare
                                      className="font-medium-5 primary"
                                      size={21}
                                    />
                                  </Media>
                                  <Media body>
                                    <Media
                                      heading
                                      className="primary media-heading"
                                      tag="h6"
                                    >
                                      Session# {notification.session_id}
                                    </Media>
                                    <p className="notification-text">
                                      {notification.message}
                                    </p>
                                  </Media>
                                </Media>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </>
                  ) : null}
                </>
              ) : null}
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem
                tag="a"
                className="text-center bg-white"
                style={{ padding: 20 }}
              >
                <span className="align-middle primary p-20">
                  <a href={`${CONSTANTS.BASE_URL}notification_list`}>
                    Read all notifications
                  </a>
                </span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status">{this.props.userRole}</span>
            </div>
          </DropdownToggle>
        </UncontrolledDropdown>

        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item mr-2"
        >
          <DropdownToggle tag="a" className="nav-link position-relative">
            <a className="nav-link" exact href={`${CONSTANTS.BASE_URL}logout`}>
              <Icon.Power size={21} />
            </a>
          </DropdownToggle>
        </UncontrolledDropdown>
      </ul>
    );
  }
}
export default NavbarUser;
