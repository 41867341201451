import React from "react"
import { Card, CardBody } from "reactstrap"
import { Award } from "react-feather"

import decorLeft from "../../assets/img/decore-left.png"
import decorRight from "../../assets/img/decore-right.png"
import LoginService from "../../services/LoginService";

class SalesCard extends React.Component {
  userName(){
    let authData = LoginService.getLoggedInAuthData();
    let username = authData?.staff_name ? authData.staff_name : 'Manager';
    return username;
  };
  render() {
    return (
      <Card className="bg-analytics text-white sales-card">
        <CardBody className="text-center">
          <img src={decorLeft} alt="card-img-left" className="img-left" />
          <img src={decorRight} alt="card-img-right" className="img-right" />
          <div className="avatar avatar-xl bg-primary shadow avatar-dashboard mt-0" style={{backgroundColor: "#7367f0 !important"}}>
            <div className="avatar-content">
              <Award className="text-white" size={28} />
            </div>
          </div>
          <div className="award-info text-center">
            <h1 className="mb-2 text-white">Welcome to Tablet Order Card</h1>
            <h2 className="mb-2 text-white">{this.userName()}</h2>
          </div>
        </CardBody>
      </Card>
    )
  }
}
export default SalesCard
