import React from "react";
import { Row, Col } from "reactstrap";
import "../../assets/scss/pages/dashboard-analytics.scss";
import "../../assets/scss/plugins/charts/apex-charts.scss";
import StatisticsCard from "../../components/@vuexy/statisticsCard/StatisticsCard";
import RecentLocationForAdmin from "./RecentLocationForAdmin";
import { ShoppingBag , Check , DollarSign , Globe , List , Key , Users, Bookmark } from "react-feather";
import TopLocationsBasedOnSessions from "./TopLocationsBasedOnSessions";
import TopCityForLocation from "./TopCityForLocation";
import TopStatesForLocation from "./TopStatesForLocation";
import TopCategoriesForLocation from "./TopCategoriesForLocation";
import LastestSessionListAdminDashboard from "./LastestSessionListAdminDashboard";
class AdministratorDashboard extends React.Component {
  state = {};

  getChartOptions = (color) => {
    let ordersReceived = {
      chart: {
        id: "revenue",
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      grid: {
        show: false,
      },
      colors: [color],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2.5,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.7,
          opacityTo: 0.5,
          stops: [0, 80, 100],
        },
      },

      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        x: { show: false },
      },
    };
    return ordersReceived;
  };

  render() {
    const {
      props: { data, location },
    } = this;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <h4>Restaurants Stats</h4>
          </Col>
        </Row>
        <Row>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="primary"
              icon={<ShoppingBag className="primary" size={22} />}
              stat={data.overAllLocations}
              statTitle="Total Restaurants"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="success"
              icon={<ShoppingBag className="success" size={22} />}
              stat={data.localTreatedLocations}
              statTitle="Local Restaurants"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="warning"
              icon={<ShoppingBag className="warning" size={22} />}
              stat={data.locationCountInLast7Days}
              statTitle="Restaurants in 7 Days"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="danger"
              icon={<ShoppingBag className="danger" size={22} />}
              stat={data.locationCountInLast30Days}
              statTitle="Restaurants in 30 Days"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="info"
              icon={<ShoppingBag className="info" size={22} />}
              stat={data.localLocationCountInLast7Days}
              statTitle="Local Restaurants in 7 Days"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="dark"
              icon={<ShoppingBag className="dark" size={22} />}
              stat={data.localLocationCountInLast30Days}
              statTitle="Local Restaurants in 30 Days"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Overall Stats</h4>
          </Col>
        </Row>
        <Row>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="primary"
              icon={<Check className="primary" size={22} />}
              stat={data.total_categories}
              statTitle="Total Categories"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="success"
              icon={<DollarSign className="success" size={22} />}
              stat={data.total_currencies}
              statTitle="Total Currencies"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="info"
              icon={<Globe className="info" size={22} />}
              stat={data.total_countries}
              statTitle="Total Countries"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="warning"
              icon={<List className="warning" size={22} />}
              stat={data.total_items}
              statTitle="Total Items"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="dark"
              icon={<Key className="dark" size={22} />}
              stat={data.total_orders}
              statTitle="Total Orders"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="danger"
              icon={<Users className="danger" size={22} />}
              stat={data.total_staff}
              statTitle="Total Staff"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconRight
              iconBg="success"
              icon={<Bookmark className="success" size={22} />}
              stat={data.total_tables}
              statTitle="Total Tables"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6" sm="12">
            <TopLocationsBasedOnSessions
              topLocations={data.mostSessionInLocation}
              dataText={"Locations based on sessions"}
            />
          </Col>
          <Col md="6" sm="12">
            <TopCityForLocation
              topLocations={data.mostLocationsInCity}
              dataText={"Based on locations"}
            />
          </Col>
          <Col md="6" sm="12">
            <TopStatesForLocation
              topLocations={data.mostLocationsInStates}
              dataText={"Based on registered locations"}
            />
          </Col>
          <Col md="6" sm="12">
            <TopCategoriesForLocation
              topLocations={data.mostLocationsInCategory}
              dataText={"Found in locations"}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <RecentLocationForAdmin data={data.recentLocationRegistered} />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <LastestSessionListAdminDashboard data={data.recentSessions} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default AdministratorDashboard;
