import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
  Col,
  Media,
} from "reactstrap";

import Radio from "../../@vuexy/radio/RadioVuexy";
import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";

import { ClipLoader } from "react-spinners";
import Select from "react-select";
import { Image, Plus, X } from "react-feather";
import ImageHelper from "../../../helpers/ImageHelper";
import * as classnames from "classnames";
import MenuModifiersList from "../MenuModifiersList";
import ConfirmationModal from "./ConfirmationModal";
import GlobalModifiersFormModal from "./GlobalModifiersFormModal";
import MenuService from "../../../services/MenuService";
import CONSTANTS from "../../../constants";

const MenuItemModal = (props) => {
  const saveAction = () => {
    if (isOpen) {
      submitItem();
    }
  };

  const keydownHandler = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) saveAction();
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    return () => document.removeEventListener("keydown", keydownHandler);
  });

  const dummyItem = {
    menu_name: "",
    menu_description: "",
    menu_status: 1,
    menu_price: "",
    menu_photo: "",
    menu_category_id: null,
    stock_qty: 0,
    minimum_qty: 0,
    subtract_stock: 0,
    mealtime_id: 0,
    pre_rating: 4.0,
    review_photo: "",
    ratable: 1,
    MenuOptions: [],
    menu_tax: 0,
    highlighted: 0
  };

  const menuType = [
    {
      value: "kitchen",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.KITCHEN,
    },
    {
      value: "bar",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.BAR,
    },
    {
      value: "dessert",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.DESSERT,
    },
  ];

  let {
    isOpen,
    toggleModal,
    selectedCategory,
    categories,
    deleteMenu,
    saveItem,
    deleting,
    location,
    allDishTags,
    selectedDishTags,
    allAllergicItems,
    selectedAllergicItems,
  } = props;

  let itemProp = props.item
    ? props.item
    : JSON.parse(JSON.stringify(dummyItem));
  
  let selectedMenuType = {};
  if (props.item?.menu_type) {
    selectedMenuType = menuType.map((type) => {
      return type.value === props.item?.menu_type ? type : null;
    });
  }

  const [item, setItem] = useState(itemProp);
  const [saving, setSaving] = useState(false);
  const [nameError, setError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [tab, setTab] = useState(1);
  const [prevOpenState, setPrevOpenState] = useState(isOpen);
  const [confirmationDialog, setConfirmationDialog] = useState({
    show: false,
    acceptCallback: () => {},
    rejectCallback: () => {},
    title: "",
  });

  const onValidateDropDownError = (value, name) => {
    console.log(value, name);
    setDropDownError((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };

  const [errorDropDownError, setDropDownError] = useState({
    category: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
    dishtag: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
    menutype: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
    allergyitems: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
  });

  const [globalModifiersDialog, setGlobalModifiersDialog] = useState(false);

  const toggleGlobalModifierModal = (changeTab = false) => {
    setGlobalModifiersDialog(!globalModifiersDialog);
    if (changeTab) toggle(2);
  };

  const getSelectedModifiers = () => {
    return item.MenuOptions.map((mo) => mo.option_id);
  };

  const showConfirmationDialog = (accept, reject, title) => {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        setConfirmationDialog(defaultState);
      },
      rejectCallback: () => {
        setConfirmationDialog(defaultState);
        if (reject) reject();
      },
      title: title,
    };
    setConfirmationDialog(confirmationDialog);
  };

  let inputRef = React.createRef();
  let fileRef = React.createRef();

  const toggle = (sTab) => {
    if (tab !== sTab) {
      setTab(sTab);
    }
  };

  const updateItem = (key, val) => {
    if(key === "highlighted"){
      val = val == 0 ? 1 : 0;  
    }
    const cat = JSON.parse(JSON.stringify(item));
    cat[key] = val;
    if (key === "img" && val === null) cat.deletePhoto = true;
    setItem(cat);
    if (key === "menu_category") onValidateDropDownError("", "category");
  };

  const updateDishtags = (val) => {
    const cat = JSON.parse(JSON.stringify(item));
    cat["menu_tags"] = val;
    setItem(cat);
    onValidateDropDownError("", "dishtag");
  };

  const updateAllergicItem = (val) => {
    const cat = JSON.parse(JSON.stringify(item));
    cat["menu_allergy_items"] = val;
    setItem(cat);
    onValidateDropDownError("", "allergyitems");
  };

  const updateMenuType = (val) => {
    const cat = JSON.parse(JSON.stringify(item));
    cat["menu_type"] = val.value;
    setItem(cat);
    onValidateDropDownError("", "menutype");
  };

  const handleChange = (e) => {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      const allowedFileTypes = ["jpg", "png", "jpeg", "webp", "svg"];
      const fileExtension = e.target.files[0].name.split(".").at(-1);
      if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(
          `Upload image does not support. Image type must be ${allowedFileTypes.join(
            ", "
          )}`
        );
        return false;
      }

      var size = parseFloat(e.target.files[0].size / (1024 * 1024 * 5)).toFixed(
        2
      );
      if (size > 5) {
        alert("Please select image size less than 5 MB");
        return false;
      }

      let reader = new FileReader();
      reader.onload = (ev) => {
        const itemProp = JSON.parse(JSON.stringify(item));
        itemProp.img = ev.target.result;
        itemProp.deletePhoto = false;
        setItem(itemProp);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const delMenu = () => {
    if (saving || deleting) return;
    deleteMenu(item);
  };

  useEffect(() => {
    let itemProp = props.item
      ? props.item
      : JSON.parse(JSON.stringify(dummyItem));
    itemProp.menu_category = selectedCategory;
    itemProp.img = ImageHelper.getImageURL(
      itemProp.menu_photo,
      "?func=crop&w=120&h=80"
    );
    itemProp.menu_tags = selectedDishTags;
    itemProp.menu_allergy_items = selectedAllergicItems;
    isOpen = props.isOpen;
    setSaving(false);
    deleting = props.deleting;
    setError(false);
    if (prevOpenState !== isOpen) {
      setPrevOpenState(isOpen);
      setTab(1);
    }
    setItem(itemProp);
    setPriceError(false);
  }, [props]);

  const submitItem = () => {
    if (saving || deleting) return;
    if (item.menu_name === "") {
      setError(true);
      return;
    }
    if (
      item.menu_category.value === null ||
      item.menu_category.value === undefined
    ) {
      onValidateDropDownError("Please select category", "category");
      return false;
    }

    if (item.menu_type === null || item.menu_type === "") {
      onValidateDropDownError("Please select menu type", "menutype");
      return false;
    }

    if (item.menu_tags.length <= 0) {
      onValidateDropDownError("Please select menu tag", "dishtag");
      return false;
    }

    if (
      item.menu_price === "" ||
      item.menu_price == 0 ||
      isNaN(item.menu_price)
    ) {
      setPriceError(true);
      return;
    }

    setSaving(true);
    saveItem(item);
  };

  const fetchGlobalModifiersForItem = async () => {
    const modifiers = await MenuService.getModifiers(item.loc_id, item.menu_id);
    const it = JSON.parse(JSON.stringify(item));
    it.MenuOptions = modifiers;
    setItem(it);
  };

  const categoryOptions = categories.map((cat) => ({
    value: cat.id,
    label: cat.name,
  }));
  const allDishTagOptions = allDishTags.map((tag) => ({
    value: tag.id,
    label: tag.title,
  }));

  const allAllergicItemOptions = allAllergicItems.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal()}
        onOpened={() => {
          if (props.item) return;
          setTimeout(() => inputRef.current.focus(), 1);
        }}
        className="modal-dialog-centered d-sm-margin-0 p-0"
        backdrop="static"
      >
        <div className="modal-header display-flex full-width flex-space-between">
          <h5 className="modal-title">
            {props.item?.menu_name ? props.item.menu_name : "New Item"}
          </h5>
          <X size={20} onClick={toggleModal} />
        </div>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames(
                  {
                    active: tab === 1,
                  },
                  "content-dark-bg-color"
                )}
                onClick={() => {
                  toggle(1);
                }}
              >
                Details
              </NavLink>
            </NavItem>
            {props.item?.menu_name && (
              <NavItem>
                <NavLink
                  className={classnames(
                    {
                      active: tab === 2,
                    },
                    "content-dark-bg-color"
                  )}
                  onClick={() => {
                    toggle(2);
                  }}
                >
                  Variants
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={tab}>
            <TabPane tabId={1} className="content-dark-bg-color">
              <FormGroup className="row">
                <Col md={6}>
                  <Label for="name">Item Name:</Label>
                  <Input
                    innerRef={inputRef}
                    type="text"
                    id="name"
                    value={item.menu_name}
                    placeholder="Enter Item Name"
                    invalid={nameError}
                    onChange={(e) => updateItem("menu_name", e.target.value)}
                  />
                </Col>
                <Col md={6} className="pl-md-0">
                  <Label
                    for="category"
                    style={{
                      color:
                        errorDropDownError.category.errorMsg !== ""
                          ? "red"
                          : null,
                    }}
                  >
                    Category:
                  </Label>
                  <Select
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "var(--primary)"
                          : state.isFocused
                          ? "#7367f099"
                          : "#fff",
                      }),
                    }}
                    className="React no-pointer-events"
                    classNamePrefix="select"
                    defaultValue={item.menu_category}
                    name="color"
                    options={categoryOptions}
                    onChange={(e) => updateItem("menu_category", e)}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col md={12}>
                  <Label
                    for="category"
                    style={{
                      color:
                        errorDropDownError.menutype.errorMsg !== ""
                          ? "red"
                          : null,
                    }}
                  >
                    Menu Type:
                  </Label>
                  <Select
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "var(--primary)"
                          : state.isFocused
                          ? "#7367f099"
                          : "#fff",
                      }),
                    }}
                    className="React no-pointer-events"
                    classNamePrefix="select"
                    defaultValue={selectedMenuType}
                    name="menu_type"
                    options={menuType}
                    onChange={(e) => updateMenuType(e)}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col md={12}>
                  <Label
                    for="category"
                    style={{
                      color:
                        errorDropDownError.dishtag.errorMsg !== ""
                          ? "red"
                          : null,
                    }}
                  >
                    Dish Tags:
                  </Label>
                  <Select
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "var(--primary)"
                          : state.isFocused
                          ? "#7367f099"
                          : "#fff",
                      }),
                    }}
                    className="React no-pointer-events"
                    classNamePrefix="select"
                    defaultValue={selectedDishTags}
                    name="color"
                    options={allDishTagOptions}
                    onChange={(e) => updateDishtags(e)}
                    isMulti
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col md={12}>
                  <Label
                    for="category"
                    style={{
                      color:
                        errorDropDownError.allergyitems.errorMsg !== ""
                          ? "red"
                          : null,
                    }}
                  >
                    Allergic Items:
                  </Label>
                  <Select
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "var(--primary)"
                          : state.isFocused
                          ? "#7367f099"
                          : "#fff",
                      }),
                    }}
                    className="React no-pointer-events"
                    classNamePrefix="select"
                    defaultValue={selectedAllergicItems}
                    name="color"
                    options={allAllergicItemOptions}
                    onChange={(e) => updateAllergicItem(e)}
                    isMulti
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col md={6}>
                  <Label for="photoDiv">Image:</Label>
                  <div
                    tabIndex="0"
                    id="photoDiv"
                    className="display-flex flex-column border border-2 border-light cursor-pointer"
                    onKeyUp={(e) =>
                      e.keyCode === 13 && !e.ctrlKey
                        ? fileRef.current.click()
                        : null
                    }
                    onClick={() => fileRef.current.click()}
                    style={{ position: "relative", height: 120 }}
                  >
                    <div style={{ textAlign: "-webkit-center" }}>
                      {item.img ? (
                        <div>
                          <div
                            style={{
                              position: "absolute",
                              top: -10,
                              right: -10,
                            }}
                          >
                            <Button.Ripple
                              onClick={() => {
                                updateItem("img", null);
                                fileRef.current.value = null;
                              }}
                              style={{ width: 20, height: 20 }}
                              className="m-0 p-0"
                              color="danger"
                            >
                              <X size={14} />
                            </Button.Ripple>
                          </div>
                          <div
                            style={{ position: "absolute", bottom: 3 }}
                            className="full-width"
                          >
                            <Button.Ripple
                              className="p-25"
                              color="primary"
                              onClick={() => fileRef.current.click()}
                            >
                              <span className="font-small-1">Change Photo</span>
                            </Button.Ripple>
                          </div>
                          <Media
                            object
                            src={item.img}
                            alt="image"
                            style={{ maxWidth: 148, maxHeight: 118 }}
                            className="align-self-center"
                          />
                        </div>
                      ) : (
                        <div
                          className="align-self-center"
                          style={{ padding: 9, height: 50, width: 50 }}
                        >
                          <Image size={32} className="align-self-center" />
                        </div>
                      )}
                    </div>
                    {item.img ? null : (
                      <React.Fragment>
                        <span className="font-small-2 primary align-self-center">
                          Click to upload a file
                        </span>
                        <span className="font-small-2 color- align-self-center align-text-center">
                          JPG,PNG,JPEG,WEBP,SVG <br /> up to 5mb
                        </span>
                      </React.Fragment>
                    )}
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    name="fileInput"
                    onChange={handleChange}
                    ref={fileRef}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                </Col>
                <Col md={6}>
                  <Label for="price">Price:</Label>
                  <Input
                    type="text"
                    id="price"
                    invalid={priceError}
                    value={item.menu_price}
                    placeholder="Enter Price"
                    onChange={(e) => updateItem("menu_price", e.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for="email">Item Description:</Label>
                <Input
                  type="textarea"
                  id="name"
                  value={item.menu_description}
                  placeholder="Enter Item Description"
                  onChange={(e) =>
                    updateItem("menu_description", e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup className="row">
                <Col md={12}>
                  <Label for="menu_tax">Tax({location?.tax_label}):</Label>
                  <Input
                    type="text"
                    id="menu_tax"
                    value={item.menu_tax}
                    placeholder="Enter Tax Value"
                    onChange={(e) => updateItem("menu_tax", e.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for="status">Status:</Label>
                <div
                  id="status"
                  onChange={(e) =>
                    updateItem("menu_status", parseInt(e.target.value))
                  }
                >
                  <div className="d-inline-block mr-1">
                    <Radio
                      label="Enabled"
                      checked={item.menu_status === 1}
                      name="status"
                      value={1}
                    />
                  </div>
                  <div className="d-inline-block mr-1">
                    <Radio
                      label="Disabled"
                      name="status"
                      checked={item.menu_status === 0}
                      value={0}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="highlighted">Chef's recommended the menu item:</Label>
                <div id="highlighted">
                  <div className="d-inline-block mr-1">
                    <Checkbox
                      label="Chef's recommended"
                      color="primary"
                      defaultChecked={item.highlighted == 1}
                      value={(item.highlighted == 0 || item.highlighted === undefined) ? 0 : 1}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateItem("highlighted", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
            </TabPane>
            <TabPane tabId={2} className="content-dark-bg-color">
              <MenuModifiersList
                showConfirmationDialog={showConfirmationDialog}
                modifiers={item.MenuOptions}
                updateModifiers={updateItem.bind(this, "MenuOptions")}
                location={location}
              />
              <Button
                outline
                className="font-weight-bold custom-padding primary-2-border"
                color={"primary"}
                size="md"
                onClick={toggleGlobalModifierModal.bind(this)}
              >
                <Plus className="mx-50" size={14} />{" "}
                <span className="ml-50 mr-50 primary">Add Variants</span>
              </Button>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <div className="d-none d-sm-flex flex-space-between full-width">
            <div>
              {props.item && !props.item.is_dummy_item && (
                <Button
                  color="bg-danger-3"
                  disabled={saving || deleting}
                  onClick={delMenu}
                  className="border-danger"
                >
                  {deleting ? (
                    <ClipLoader size={15} />
                  ) : (
                    <span className="danger"> Delete </span>
                  )}
                </Button>
              )}{" "}
            </div>
            <div>
              <Button
                color="dark white"
                disabled={saving || deleting}
                onClick={() => {
                  if (saving) return;
                  toggleModal();
                }}
              >
                Cancel
              </Button>{" "}
              <Button
                color="primary"
                disabled={saving || deleting}
                onClick={submitItem}
              >
                {saving ? <ClipLoader size={15} /> : "Save"}
              </Button>{" "}
            </div>
          </div>
          <div className="d-sm-none col-12">
            <Button
              className="col-12"
              color="primary"
              disabled={saving || deleting}
              onClick={submitItem}
            >
              {saving ? <ClipLoader size={15} /> : "Save"}
            </Button>{" "}
            <Button
              className="col-12 my-50"
              color="dark white"
              disabled={saving || deleting}
              onClick={() => {
                if (saving || deleting) return;
                toggleModal();
              }}
            >
              Cancel
            </Button>{" "}
            {props.item && (
              <Button
                className="col-12"
                color="darken-1"
                disabled={saving || deleting}
                onClick={delMenu}
              >
                {deleting ? (
                  <ClipLoader size={15} />
                ) : (
                  <span className="danger"> Delete </span>
                )}
              </Button>
            )}
          </div>
          {/* <p className="d-none d-sm-inline col-12 align-text-right font-small-1 pr-0">
            Hit Ctrl + Enter to Save
          </p> */}
        </ModalFooter>
      </Modal>
      <GlobalModifiersFormModal
        fetchGlobalModifiersForItem={fetchGlobalModifiersForItem.bind(this)}
        isOpen={globalModifiersDialog}
        selectedModifiers={getSelectedModifiers()}
        toggleModal={toggleGlobalModifierModal.bind(this)}
        showConfirmationDialog={showConfirmationDialog.bind(this)}
        menu_id={item.menu_id}
        title={item.menu_name}
        loc_id={location?.loc_id}
        isDummyItem={item.is_dummy_item}
      />
      <ConfirmationModal
        title={confirmationDialog.title}
        isOpen={confirmationDialog.show}
        accept={confirmationDialog.acceptCallback.bind(this)}
        reject={confirmationDialog.rejectCallback.bind(this)}
      />
    </React.Fragment>
  );
};
export default MenuItemModal;
