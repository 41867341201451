import CONSTANTS from '../../../constants';
import CurrencyService from '../../../services/CurrencyService';

export function getAllCurrency() {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.CURRENCIES.ALL_CURRENCIES, currencies: [], loading: true });
      let currencies = await CurrencyService.getAllCurrency();
      dispatch({type: CONSTANTS.ACTIONS.CURRENCIES.ALL_CURRENCIES, currencies, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.CURRENCIES.ALL_CURRENCIES, currencies: [], loading: false });
    }
  };
}

export function createCurrency(currency) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.CURRENCIES.ALL_CURRENCIES, currencies: [], loading: true });
      let currencies = await CurrencyService.createCurrency(currency);
      dispatch({type: CONSTANTS.ACTIONS.CURRENCIES.ALL_CURRENCIES, currencies, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.CURRENCIES.ALL_CURRENCIES, currencies: [], loading: false });
    }
  };
}

export function getAllCountries() {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.COUNTRIES.ALL_COUNTRIES, countries: [], loading: true });
      let countries = await CurrencyService.getAllCountries();
      dispatch({type: CONSTANTS.ACTIONS.COUNTRIES.ALL_COUNTRIES, countries, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.COUNTRIES.ALL_COUNTRIES, countries: [], loading: false });
    }
  };
}

export function changeCurrencyStatus(data) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.CURRENCIES.ALL_CURRENCIES, currencies: [], loading: true });
      let currencies = await CurrencyService.changeCurrencyStatus(data);
      dispatch({type: CONSTANTS.ACTIONS.CURRENCIES.ALL_CURRENCIES, currencies, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.CURRENCIES.ALL_CURRENCIES, currencies: [], loading: false });
    }
  };
}