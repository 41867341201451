import React from "react";
import { Button } from "reactstrap";
import { Database } from "react-feather";
import LocationService from "../../services/LocationService";
import "../../assets/css/locationSelectHeader.scss";

const TakeDatabaseBackup = (props) => {
  return (
    <div>
      <Button
        outline
        className="font-weight-bold success-2-border custom-padding"
        color="success"
        size="md"
        onClick={() => props.onIconClick()}
      >
        <Database size={15} />
        {" Backup"}
      </Button>
    </div>
  );
};

export default TakeDatabaseBackup;
