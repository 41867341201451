import React from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Check,
  ChevronDown,
  ChevronUp,
  Edit,
  Plus,
  Trash,
  MoreVertical,
} from "react-feather";

import "../../assets/css/collapsibeCardComponent.scss";
import TimeHelper from "../../helpers/TimeHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";

import "../../assets/scss/plugins/forms/switch/react-toggle.scss";
import "react-toggle/style.css";
import Toggle from "react-toggle";

class CollapsibleCardComponent extends React.Component {
  render() {
    const {
      props: {
        item: collapseItem,
        toggleCollapse,
        isOpen,
        toggleStatus,
        toggleCategoryModal,
        toggleMenuModal,
        bodyColor,
        badgeTitle,
        deleteCardItem,
        changeStatusItem,
        updateSelectedModifiers,
        selectedIds,
        isOutlet,
      },
    } = this;

    const isEnabled = collapseItem.status == 1;

    const isOffTime =
      collapseItem.available_time == "1" &&
      !TimeHelper.checkIfTimeIsActive(
        collapseItem.local_from_time,
        collapseItem.local_to_time
      );
    let buttonClasses = "font-weight-bold custom-padding mr-1";
    if (isEnabled) buttonClasses += " success-3-border";
    else buttonClasses += " disabled-danger-3-border";

    let rightSize =
      (toggleStatus ? 1 : 0) +
      (toggleCategoryModal ? 1 : 0) +
      (deleteCardItem ? 1 : 0) +
      (updateSelectedModifiers ? 1 : 0) +
      1;
    let leftSize = 10 - rightSize;
    return (
      <div id="collapse" className="vx-collapse collapse-bordered mb-2 ">
        <Card key={collapseItem.id} className="border-radius-0-5">
          <CardHeader
            id="collapse"
            onClick={(e) => {
              if (e.target.id !== "collapse") {
                return;
              }
              toggleCollapse(collapseItem.id);
            }}
            className="border-radius-0-5  bg-white border-bottom border-primary"
            {...this.props.dragHandleProps}
          >
            <CardTitle
              id="collapse"
              className={`lead collapse-title collapsed flex-${leftSize} primary pl-2`}
            >
              {collapseItem.title}
              {badgeTitle && (
                <Badge
                  id="collapse"
                  className="ml-1 font-small-2 align-top"
                  pill
                  color="primary"
                >
                  {badgeTitle}
                </Badge>
              )}
              {isOffTime && (
                <Badge
                  id="collapse"
                  className="ml-1 font-small-2 align-top"
                  pill
                  color="primary"
                >
                  Off Time
                </Badge>
              )}
              {toggleStatus && (
                <Badge
                  id="collapse"
                  className="ml-1 font-small-2 align-top"
                  pill
                  color={isEnabled ? "success" : "danger"}
                >
                  {isEnabled ? "Enabled" : "Disabled"}
                </Badge>
              )}
            </CardTitle>
            <div
              id="collapse"
              className={`justify-content-end display-flex flex-${rightSize}`}
            >
              <UncontrolledDropdown className="chart-dropdown">
                <DropdownToggle
                  color=""
                  className="bg-transparent btn-sm border-0 p-50"
                >
                  <MoreVertical size={18} className="cursor-pointer" />
                </DropdownToggle>
                <DropdownMenu right>
                  {!isOutlet && toggleMenuModal && (
                    <DropdownItem
                      className="w-100"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleMenuModal();
                      }}
                    >
                      <span
                        id="collapse"
                        tag="small"
                        className="text-bold-500 cursor-pointer mr-1"
                      >
                        <Plus className="mr-50" size={15} />{" "}
                        <span className="align-middle">Add item</span>
                      </span>
                    </DropdownItem>
                  )}
                  {!isOutlet && toggleCategoryModal && (
                    <DropdownItem
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleCategoryModal(collapseItem);
                      }}
                    >
                      <span
                        id="collapse"
                        tag="small"
                        className="text-bold-500 cursor-pointer mr-1"
                      >
                        <Edit className="mr-50" size={15} />{" "}
                        <span className="align-middle">Edit Category</span>
                      </span>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>

              {updateSelectedModifiers && (
                <Checkbox
                  color="primary"
                  icon={<Check className="vx-icon" size={16} />}
                  defaultChecked={selectedIds.indexOf(collapseItem.id) > -1}
                  checked={selectedIds.indexOf(collapseItem.id) > -1}
                  value={collapseItem.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    updateSelectedModifiers(e.target.value);
                  }}
                />
              )}
              <span
                id="collapse"
                tag="small"
                className="text-bold-500 cursor-pointer"
              >
                {isOpen ? (
                  <ChevronUp id="collapse" size={30} />
                ) : (
                  <ChevronDown id="collapse" size={30} />
                )}
              </span>
            </div>
          </CardHeader>
          <Collapse isOpen={isOpen}>
            <CardBody className={bodyColor ? bodyColor : "menu-dark-bg-color p-0"}>
              {this.props.children}
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}
export default CollapsibleCardComponent;
