import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { store } from "./redux/storeConfig/store";
import { Layout } from "./helpers/context/Layout";
import PrivateRoute from "./components/shared/PrivateRoute";
import PublicRoute from "./components/shared/PublicRoute";
import Login from "./components/sessions/Login";
import Logout from "./components/sessions/Logout";
import OrderSessions from "./components/ordersessions/OrderSessions";
import OrderSessionsDetail from "./components/ordersessions/OrderSessionsDetail";
import Locations from "./components/locations/Locations";
import AllLocations from "./components/locations/AllLocations";
import Location from "./components/locations/Location";
import NewLocation from "./components/locations/NewLocation";
import SelectLocation from "./components/shared/SelectLocation";
import OverallStats from "./components/analytics/OverallStats";
import ItemsStats from "./components/analytics/ItemsList";
import DishTags from "./components/dishtags/DishTags";
import DishTagForm from "./components/dishtags/DishTagForm";
import AllergicItems from "./components/allergicitems/AllergicItems";
import AllergicItemsForm from "./components/allergicitems/AllergicItemsForm";
import Branding from "./components/branding/index";
import NotificationList from "./components/notificationlist";

import * as serviceWorker from "./serviceWorker";
import CONSTANTS from "./constants";

import "./components/@vuexy/rippleButton/RippleButton";
import "react-perfect-scrollbar/dist/css/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import "./index.scss";

import Menu from "./components/menus/Menu";
import ScrollToTop from "./components/shared/ScrollToTop";

import TermsConditions from "./components/terms/TermsConditions";
import CheckoutMessage from "./components/checkoutmessage/CheckoutMessage";
import AppUsers from "./components/appdata/users/AppUsers";
import Currency from "./components/currencies/Currency";
import CurrencyForm from "./components/currencies/CurrencyForm";
import Tables from "./components/tables/Tables";
import SessionTables from "./components/tables/SessionTables";
import Banners from "./components/banners/";
import TablesForm from "./components/tables/TablesForm";
import Staff from "./components/staff/Staff";
import CustomerList from "./components/staff/CustomerList";
import StaffForm from "./components/staff/StaffForm";
import Roles from "./components/roles/Roles";
import RolesForm from "./components/roles/RolesForm";
import SendPopupsOnTabs from "./components/sendpopups/SendPopupsOnTabs";
import AssignRole from "./components/staff/rolesassign/RoleAssign";
import AssignRoleForm from "./components/staff/rolesassign/RoleAssignForm";

import Modifiers from "./components/modifiers/Modifiers";
import Dashboard from "./components/dashboard/Dashboard";



ReactDOM.render(
  <Provider store={store}>
    <Layout>
      <BrowserRouter>
        <ScrollToTop />
        <div className="app-container">
          <Switch>
            <PublicRoute
              fullLayout
              exact
              path={`${CONSTANTS.BASE_URL}login`}
              component={Login}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}logout`}
              component={Logout}
              pageTitle={"Logout"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}`}
              component={Dashboard}
              pageTitle={"Dashboard"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}sessions`}
              component={OrderSessions}
              pageTitle={"Order sessions"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}sessions/:id`}
              component={OrderSessionsDetail}
              pageTitle={"Session Detail"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}select_location`}
              component={SelectLocation}
              pageTitle={"Select Restaurant"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}appdata/users`}
              component={AppUsers}
              pageTitle={"App Users"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}currencies`}
              component={Currency}
              pageTitle={"Currencies"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}currencies/new`}
              component={CurrencyForm}
              pageTitle={"Currency form"}
              goBackURL={"currencies"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}currencies/edit/:currency_id`}
              component={CurrencyForm}
              pageTitle={"Currency form"}
              goBackURL={"currencies"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}tables`}
              component={Tables}
              pageTitle={"Tables"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}tables/session`}
              component={SessionTables}
              pageTitle={"Session Tables"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}banners`}
              component={Banners}
              pageTitle={"Banners"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}tables/new`}
              component={TablesForm}
              goBackURL={"tables"}
              pageTitle={"Add New Tables"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}tables/edit/:table_id`}
              component={TablesForm}
              goBackURL={"tables"}
              pageTitle={"Edit Tables"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}staff`}
              component={Staff}
              pageTitle={"Manager Accounts"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}staff/assign/:id`}
              component={AssignRole}
              goBackURL={"staff"}
              pageTitle={"Assign Role"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}staff/assign/new/:id`}
              component={AssignRoleForm}
              goBackURL={"staff"}
              pageTitle={"Assign Role"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}staff/new`}
              component={StaffForm}
              goBackURL={"staff"}
              pageTitle={"Staff New"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}staff/edit/:id`}
              component={StaffForm}
              pageTitle={"Edit Staff"}
              goBackURL={"staff"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}roles`}
              component={Roles}
              pageTitle={"Roles"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}roles/new`}
              component={RolesForm}
              pageTitle={"Roles form"}
              goBackURL={"roles"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}roles/edit/:id`}
              component={RolesForm}
              pageTitle={"Edit Role"}
              goBackURL={"roles"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}locations/overall_stats`}
              component={OverallStats}
              pageTitle={"Overall Stats"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}locations/item_stats`}
              component={ItemsStats}
              pageTitle={"Item Stats"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}locations/modifiers`}
              component={Modifiers}
              pageTitle={"Modifiers"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}locations/menu`}
              component={Menu}
              pageTitle={"Menu"}
            />
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}locations`}
              component={Locations}
              pageTitle={"Select Restaurant"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}locations/all`}
              component={AllLocations}
              pageTitle={"Restaurants"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}locations/all/:staffid`}
              component={AllLocations}
              pageTitle={"Restaurants"}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}customers`}
              component={CustomerList}
              pageTitle={"Customer List"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}locations/new`}
              component={NewLocation}
              pageTitle={"New Restaurant"}
              goBackURL={"locations"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}locations/:location_id`}
              component={Location}
              pageTitle={"Restaurant Details"}
              goBackURL={"locations"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}dish_tags`}
              component={DishTags}
              pageTitle={"Dish Tags"}
              goBackURL={"Dashboard"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}allergic_items`}
              component={AllergicItems}
              pageTitle={"Allergic Items"}
              goBackURL={"Dashboard"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}allergic_items/add`}
              component={AllergicItemsForm}
              pageTitle={"Add New Allergic Item"}
              goBackURL={"allergic_items"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}allergic_items/edit/:item_id`}
              component={AllergicItemsForm}
              pageTitle={"Update Allergic Item"}
              goBackURL={"allergic_items"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}dish_tags/edit/:tag_id`}
              component={DishTagForm}
              goBackURL={"dish_tags"}
              pageTitle={"Edit Dish Tag"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}dish_tags/add`}
              component={DishTagForm}
              goBackURL={"dish_tags"}
              pageTitle={"Edit Dish Tag"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}send/popups`}
              component={SendPopupsOnTabs}
              pageTitle={"Send Popups on tabs"}
              goBackURL={"Dashboard"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}location/terms_conditions`}
              component={TermsConditions}
              pageTitle={"Terms & Conditions"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}checkout_message`}
              component={CheckoutMessage}
              pageTitle={"Checkout Message"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}branding`}
              component={Branding}
              pageTitle={"Branding"}
              withConfirmation={true}
            />{" "}
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}notification_list`}
              component={NotificationList}
              pageTitle={"Notification List"}
              withConfirmation={true}
            />{" "}
          </Switch>{" "}
        </div>{" "}
      </BrowserRouter>{" "}
    </Layout>{" "}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
