import React from "react";
import { Card, CardHeader, CardTitle, Table, Progress } from "reactstrap";

class LastestSessionListAdminDashboard extends React.Component {
  render() {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Lastest Sessions</CardTitle>
        </CardHeader>
        <Table
          responsive
          className="dashboard-table table-hover-animation mb-0 mt-1"
        >
          <thead>
            <tr>
              <th>Location</th>
              <th>TABLE</th>
              <th>SESSION DATE</th>
              <th>START / END TIME</th>
              <th className="text-center">TOTAL ITEMS</th>
              <th className="text-center">AMOUNT</th>
              <th className="text-center">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.length > 0 ? (
              this.props.data.map((item) => {
                return (
                  <tr>
                    <td>{item.location_name}</td>
                    <td>{item.table_name}</td>
                    <td>{item.session_date}</td>
                    <td>
                      {item.start_time}
                      {item.end_time !== null ? " / " + item.end_time : " / In Progress"}
                    </td>
                    <td className="text-center">{item.total_items}</td>
                    <td className="text-center">
                      {item.order_total > 0
                        ? item.currency_symbol
                        : null}{" "}
                      {item.order_total}
                    </td>
                    <td className="text-center">{item.session_status.toUpperCase()}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td span="6">No Session Found</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    );
  }
}
export default LastestSessionListAdminDashboard;
