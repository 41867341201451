import { combineReducers } from 'redux'
import { order } from './OrderReducer';
import { orders } from './OrdersReducer';
import { sessionslist } from './SessionsListReducer';

const ordersReducer = combineReducers({
  order,
  orders,
  sessionslist
});

export default ordersReducer;
