import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Col,
  Label,
  Media,
} from "reactstrap";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { X, Image } from "react-feather";
import LocationService from "../../services/LocationService";
import BrandingService from "../../services/BrandingService";
import ImageHelper from "../../helpers/ImageHelper";

class Branding extends React.Component {
  state = {
    branding: null,
    loading: true,
    location: null,
    logo: null,
  };
  async componentDidMount() {
    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false, location: location });
    } else {
      this.setState({ loading: false });
    }
  }

  async refreshData(loc_id, initialize = true) {
    this.setState({ loading: initialize });
    let branding = await BrandingService.getBranding(loc_id);
    this.setState({ branding: branding });
  }

  async saveBranding() {
    this.setState({ loading: true });
    const fileInput = document.querySelector("#bannerFileInput");
    const formData = new FormData();
    if (fileInput.files.length > 0) {
      formData.append("file", fileInput.files[0]);
    }
    formData.append("brandingItem", JSON.stringify(this.state.branding));
    let branding = await BrandingService.saveBranding(
      this.state.location.loc_id,
      formData
    );
    this.setState({ branding: branding, loading: false });
  }

  async handleChange(e) {
    if (e.target.files.length > 0) {
      const allowedFileTypes = ["jpg", "png", "jpeg", "webp", "svg"];
      const fileExtension = e.target.files[0].name.split(".").at(-1);
      if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(
          `Upload image does not support. Image type must be ${allowedFileTypes.join(
            ", "
          )}`
        );
        return false;
      }

      var size = parseFloat(e.target.files[0].size / (1024 * 1024 * 5)).toFixed(
        2
      );
      if (size > 5) {
        alert("Please select image size less than 5 MB");
        return false;
      }
      let reader = new FileReader();
      let itemProps = this.state.branding;
      itemProps.deletePhoto = true;
      reader.onload = (ev) => {
        this.setState({ logo: ev.target.result, branding: itemProps });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  changeBaseColor(index, value) {
    console.log()
    let itemProps = this.state.branding;
    itemProps[index] = value;
    this.setState({ branding: itemProps });
  }

  render() {
    let fileRef = React.createRef();

    if (this.state.loading) return <SpinnerComponent />;
    return (
      <>
        <div className="location">
          <div className="row">
            <div className="col-12">
              <Card>
                <CardHeader>
                  <CardTitle>Branding</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="row">
                    {this.state.branding.logo !== null &&
                      this.state.branding.logo !== "" && (
                        <div
                          className="col-sm-6 col-md-4 mb-5"
                          style={{ border: "1px solid #e6e7e9", textAlign: "center" }}
                        >
                          <img
                            src={ImageHelper.getImageURL(
                              this.state.branding.logo
                            )}
                            alt="swiper 1"
                            className="img-fluid"
                            style={{ minHeight: "200px", maxHeight: "200px" }}
                          />
                        </div>
                      )}
                    <Col md={4} sm={6}>
                      <Label for="photoDiv">Image:</Label>
                      <div
                        tabIndex="0"
                        id="photoDiv"
                        className="display-flex flex-column border border-2 border-light cursor-pointer"
                        onKeyUp={(e) =>
                          e.keyCode === 13 && !e.ctrlKey
                            ? fileRef.current.click()
                            : null
                        }
                        onClick={() => fileRef.current.click()}
                        style={{ position: "relative", height: 120 }}
                      >
                        <div style={{ textAlign: "-webkit-center" }}>
                          {this.state.logo ? (
                            <div>
                              <div
                                style={{
                                  position: "absolute",
                                  top: -10,
                                  right: -10,
                                }}
                              >
                                <Button.Ripple
                                  style={{ width: 20, height: 20 }}
                                  onClick={() => {
                                    fileRef.current.value = null;
                                    let itemProps = this.state.branding;
                                    itemProps.deletePhoto = false;
                                    this.setState({
                                      logo: null,
                                      branding: itemProps,
                                    });
                                  }}
                                  className="m-0 p-0"
                                  color="danger"
                                >
                                  <X size={14} />
                                </Button.Ripple>
                              </div>
                              <div
                                style={{ position: "absolute", bottom: 3 }}
                                className="full-width"
                              >
                                <Button.Ripple
                                  className="p-25"
                                  color="primary"
                                  onClick={() => fileRef.current.click()}
                                >
                                  <span className="font-small-1">
                                    Change Photo
                                  </span>
                                </Button.Ripple>
                              </div>
                              <Media
                                object
                                src={this.state.logo}
                                alt="image"
                                style={{ maxWidth: "100%", maxHeight: 118 }}
                                className="align-self-center"
                              />
                            </div>
                          ) : (
                            <div
                              className="align-self-center"
                              style={{ padding: 9, height: 50, width: 50 }}
                            >
                              <Image size={32} className="align-self-center" />
                            </div>
                          )}
                        </div>
                        {this.state.logo == null ? (
                          <React.Fragment>
                            <span className="font-small-2 primary align-self-center">
                              Click to upload a file
                            </span>
                            <span className="font-small-2 color- align-self-center align-text-center">
                              JPG,PNG,JPEG,WEBP,SVG <br /> up to 5mb
                            </span>
                          </React.Fragment>
                        ) : null}
                      </div>
                      <input
                        type="file"
                        id="bannerFileInput"
                        name="fileInput"
                        onChange={(e) => this.handleChange(e)}
                        ref={fileRef}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                    </Col>
                  </div>
                  <div className="row mt-3">
                    <Col md={"2"}>
                      <Label for="photoDiv">Base Color:</Label>
                      <input
                        className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
                        type="color"
                        id="basecolor"
                        name="basecolor"
                        value={this.state.branding.basecolor}
                        onChange={(e) =>
                          this.changeBaseColor("basecolor", e.target.value)
                        }
                      />
                    </Col>
                    <Col md={"2"}>
                      <Label for="photoDiv">Highlight Color:</Label>
                      <input
                        className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
                        type="color"
                        id="highlight_color"
                        name="highlight_color"
                        value={this.state.branding.highlight_color}
                        onChange={(e) =>
                          this.changeBaseColor(
                            "highlight_color",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                    <Col md={"2"}>
                      <Label for="photoDiv">Highlight Text Color:</Label>
                      <input
                        className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
                        type="color"
                        id="highlight_text_color"
                        name="highlight_text_color"
                        value={this.state.branding.highlight_text_color}
                        onChange={(e) =>
                          this.changeBaseColor(
                            "highlight_text_color",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                    <Col md={"2"}>
                      <Label for="photoDiv">H2 Color:</Label>
                      <input
                        className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
                        type="color"
                        id="h2_color"
                        name="h2_color"
                        value={this.state.branding.h2_color}
                        onChange={(e) =>
                          this.changeBaseColor("h2_color", e.target.value)
                        }
                      />
                    </Col>
                    <Col md={"2"}>
                      <Label for="photoDiv">H2 Text Color:</Label>
                      <input
                        className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
                        type="color"
                        id="h2_text_color"
                        name="h2_text_color"
                        value={this.state.branding.h2_text_color}
                        onChange={(e) =>
                          this.changeBaseColor("h2_text_color", e.target.value)
                        }
                      />
                    </Col>
                  </div>
                  <div className="row w-100 mt-2 p-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.saveBranding()}
                    >
                      Save Branding
                    </button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Branding;
