import React, { useEffect, useState } from "react";
import { Button, ListGroup, ListGroupItem, Media, Badge } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ViewHelper from "../../helpers/ViewHelper";
import {
  Star,
  StopCircle,
  Edit,
  Play,
  Pause,
  Image,
  ShieldOff,
  Check,
} from "react-feather";

import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import ImageHelper from "../../helpers/ImageHelper";
import ParamHelper from "../../helpers/ParamHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFire,
  faGlassCheers,
  faBirthdayCake,
} from "@fortawesome/free-solid-svg-icons";

// datatable
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

// get data from array
const getItems = (listItems, location) =>
  listItems.map((k) => {
    let content = (
      <span>
        {" "}
        {ViewHelper.toCurrency(
          k.menu_price,
          location.Currency.currency_code,
          location.decimal_places
        )}
        {k.overall_rating ? (
          <span>
            {" "}
            <StopCircle size={2} fill="white" className="" />{" "}
            <Star
              fill="gold"
              color="gold"
              size={10}
              className="align-baseline"
            />{" "}
            {ViewHelper.toDecimal(k.overall_rating, 1)}{" "}
          </span>
        ) : null}
      </span>
    );
    return {
      id: `${k.menu_id}`,
      img: ImageHelper.getImageURL(k.thumb_path, "?func=crop&w=50&h=50"),
      name: k.menu_name,
      content: content,
      status: k.menu_status,
      ...k,
    };
  });

const DnDListView = (props) => {
  const [items, setItems] = useState([]);

  const {
    hideEditableButtons,
    updateSelectedModifiers,
    selectedIds,
    isDragDisabled: dragDisableProp,
    categorySelected,
    toggleStatus,
    updateMenuPriority,
    location,
    menu,
    isOutlet,
  } = props;

  const isDragDisabled = dragDisableProp || isOutlet;

  useEffect(() => {
    const itemsCopy = getItems(menu, location);
    setItems(itemsCopy);
  }, [menu]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const itemsCopy = ViewHelper.reorder(
      items,
      result.source.index,
      result.destination.index,
      true
    );
    setItems(itemsCopy);
    updateMenuPriority(
      location.loc_id,
      ParamHelper.getMenusForBulkUpdate(itemsCopy)
    );
  };
  return (
    <ListGroup id="list-group-dnd">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="drag-wrapper"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.toggleMenuModal(item);
                      }}
                    >
                      <ListGroupItem
                        className="no-border-bottom-radius no-border-top-radius menu-item-li"
                        disabled={categorySelected}
                      >
                        <div className="display-flex flex-row flex-space-between">
                          <div>
                            <Media>
                              <Media left tag="div">
                                {item.img ? (
                                  <Media
                                    object
                                    src={item.img}
                                    className="rounded-circle mr-2"
                                    style={{ objectFit: "cover" }}
                                    alt="image"
                                    height="50"
                                    width="50"
                                  />
                                ) : (
                                  <div
                                    className="rounded-circle mr-2"
                                    style={{
                                      padding: 9,
                                      height: 50,
                                      width: 50,
                                      backgroundColor: "#ffffff15",
                                    }}
                                  >
                                    <Image
                                      size={32}
                                      className="align-self-center rounded"
                                    />
                                  </div>
                                )}
                              </Media>
                              <Media body>
                                <p className="mt-0 mb-0">{item.name}</p>
                                <div className="row">
                                  <div className="col-12">
                                    {location.Currency.currency_symbol}{" "}
                                    {item.menu_price}
                                  </div>
                                  {item.MenuTags.length > 0 ? (
                                    <div className="col-12 mb-1">
                                      {item.MenuTags.map((menu_tag) => {
                                        return (
                                          <>
                                            <Badge>
                                              {menu_tag?.Dish_Tag?.tag_name}
                                            </Badge>
                                            &nbsp;
                                          </>
                                        );
                                      })}
                                    </div>
                                  ) : null}
                                </div>
                                {item.menu_type === "kitchen" ? (
                                  <FontAwesomeIcon
                                    className="mr-1"
                                    icon={faFire}
                                  />
                                ) : item.menu_type === "bar" ? (
                                  <FontAwesomeIcon
                                    className="mr-1"
                                    icon={faGlassCheers}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="mr-1"
                                    icon={faBirthdayCake}
                                  />
                                )}
                                {/* {item.content} */}
                                <Badge
                                  id="collapse"
                                  className="font-small-2 align-top"
                                  pill
                                  color={
                                    item.status === 1
                                      ? "success"
                                      : item.status === 0
                                        ? "danger"
                                        : "warning"
                                  }
                                >
                                  {item.status === 1
                                    ? "Enabled"
                                    : item.status === 0
                                      ? "Disabled"
                                      : "Out of Stock"}
                                </Badge>
                                {item.MenuOptions.length > 0 && (
                                  <span>
                                    {" "}
                                    {item.MenuOptions.length} Variants
                                  </span>
                                )}
                              </Media>
                            </Media>
                          </div>
                          {/* {item.content} */}
                          {!hideEditableButtons && (
                            <div className="align-self-center display-flex">
                              <span
                                tag="small"
                                className="text-bold-500 cursor-pointer mr-1"
                              >
                                {item.highlighted ? (
                                  <Star
                                    fill="gold"
                                    color="gold"
                                    size={25}
                                    className="align-baseline"
                                  />
                                ) : null}
                              </span>
                              {!isOutlet && (
                                <span
                                  tag="small"
                                  className="text-bold-500 cursor-pointer mr-1"
                                >
                                  <Edit
                                    size={25}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      props.toggleMenuModal(item);
                                    }}
                                  />
                                </span>
                              )}
                            </div>
                          )}
                          {!isOutlet && updateSelectedModifiers && (
                            <Checkbox
                              color="primary"
                              icon={<Check className="vx-icon" size={16} />}
                              defaultChecked={
                                selectedIds.indexOf(parseInt(item.id)) > -1 ||
                                categorySelected
                              }
                              checked={
                                selectedIds.indexOf(parseInt(item.id)) > -1 ||
                                categorySelected
                              }
                              value={item.id}
                              onClick={(e) => {
                                e.stopPropagation();
                                updateSelectedModifiers(e.target.value);
                              }}
                            />
                          )}
                        </div>
                      </ListGroupItem>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ListGroup>
  );
};

export default DnDListView;
