import * as firebase from 'firebase/app';
import 'firebase/messaging';
import LoginService from './LoginService';
import CONSTANTS from '../constants';
import { customFetch as fetch } from '../helpers/FetchHelper';
import { func } from 'prop-types';

export default {
  getToken,
  saveToken,
  setTokenInCache,
  getTokenFromCache,
  removeTokenFromCache,
  getAllNotificationForWeb,
  updateNavBarNotificationStatus
};

function initializeFirebaseApp() {
  if(!firebase.apps.length) firebase.initializeApp(CONSTANTS.FIREBASE_CONFIG);
  return firebase.messaging();
}

function getToken() {
  if(firebase.messaging.isSupported()) return getTokenForChromium();
  return getTokenForSafari();
}

function getTokenForChromium() {
  const messaging = initializeFirebaseApp();
  try {
    return messaging.getToken({vapidKey: CONSTANTS.FIREBASE_CONFIG.vapidKey});
  } catch (error) {
    if (error.code === 'messaging/token-unsubscribe-failed') return getTokenForChromium();
    console.log('Error: ', error);
  }
}

function getTokenForSafari() {
  if ('safari' in window && 'pushNotification' in window.safari) {
    let permissionData = window.safari.pushNotification.permission(CONSTANTS.WEB_PUSH_ID);
    if (permissionData.permission === 'default')
      return new Promise((resolve) => {
        window.safari.pushNotification.requestPermission(CONSTANTS.API_URL, CONSTANTS.WEB_PUSH_ID, {},
          (permissionData) => {
          resolve(permissionData.deviceToken);
        });
      });
    return permissionData.deviceToken;
  }
}

async function saveToken(messagingToken) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.USER_SUBSCRIPTIONS.DEFAULT}`, {
    method: 'POST',
    body: JSON.stringify({ subscription: messagingToken }),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not save token');
}

function setTokenInCache(token) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_fcmToken`, token);
}

function getTokenFromCache() {
  return localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_fcmToken`);
}

function removeTokenFromCache() {
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_fcmToken`);
}


async function getAllNotificationForWeb(loc_id , page=0){
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.NOTIFICATION.DEFAULT(loc_id , page)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not delete tags');
}

async function updateNavBarNotificationStatus(notification_id){
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.NOTIFICATION.UPDATE_STATUS(notification_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not delete tags');
}
