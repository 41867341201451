import React from "react";
import * as Icon from "react-feather";

const managerNavigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
    starred: false,
  },
  {
    id: "tables",
    title: "Tables",
    type: "collapse",
    icon: <Icon.Tablet size={20} />,
    permissions: ["admin"],
    starred: false,
    children: [
      {
        id: "tables_status",
        title: "Ongoing Sessions",
        type: "item",
        icon: <Icon.BookOpen size={20} />,
        permissions: ["admin"],
        navLink: "/tables/session",
        starred: true,
      },
      {
        id: "sessions",
        title: "Sessions History",
        type: "item",
        icon: <Icon.Key size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/sessions",
        starred: false,
      },
      {
        id: "tables_list",
        title: "Tables List",
        type: "item",
        icon: <Icon.Bookmark size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/tables",
        starred: true,
      },
    ],
  },
  {
    id: "menu-manager",
    title: "Menu",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["admin", "editor"],
    starred: false,
    children: [
      {
        id: "menu_list",
        title: "Menu List",
        type: "item",
        icon: <Icon.List size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/menu",
        starred: true,
      },
      {
        id: "allergic-items",
        title: "Allergic Items",
        type: "item",
        icon: <Icon.CloudSnow size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/allergic_items",
        starred: false,
      },
      {
        id: "dish-tags",
        title: "Dish Tags",
        type: "item",
        icon: <Icon.Command size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/dish_tags",
        starred: false,
      },
      {
        id: "modifiers",
        title: "Variants",
        type: "item",
        icon: <Icon.Archive size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/modifiers",
        starred: false,
      },
    ],
  },
  {
    id: "admin",
    title: "Admin",
    type: "collapse",
    icon: <Icon.User size={16} />,
    children: [
      {
        id: "branding",
        title: "Branding",
        type: "item",
        icon: <Icon.Flag size={20} />,
        permissions: ["admin"],
        navLink: "/branding",
        starred: false,
      },
      {
        id: "terms_conditions",
        title: "Terms & Conditions",
        type: "item",
        icon: <Icon.Type size={20} />,
        permissions: ["admin"],
        navLink: "/location/terms_conditions",
        starred: false,
      },
      {
        id: "banner",
        title: "Banners",
        type: "item",
        icon: <Icon.Image size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/banners",
        starred: false,
      },
      {
        id: "checkout-message",
        title: "Checkout Message",
        type: "item",
        icon: <Icon.MessageSquare size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/checkout_message",
        starred: false,
      },
      {
        id: "sendpopup",
        title: "Send Popups",
        type: "item",
        icon: <Icon.Bell size={20} />,
        permissions: ["admin"],
        navLink: "/send/popups",
        starred: true,
      },
    ],
    starred: false,
  },
  {
    id: "analytics",
    title: "Sales",
    type: "collapse",
    icon: <Icon.BarChart2 size={16} />,
    children: [
      {
        id: "overall-stats",
        title: "Overall Stats",
        type: "item",
        icon: <Icon.Activity size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/overall_stats",
      },
      {
        id: "item-stats",
        title: "Item Stats",
        type: "item",
        icon: <Icon.List size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/item_stats",
      },
    ],
    starred: false,
  },


  {
    id: "logout",
    title: "Logout",
    type: "item",
    icon: <Icon.Power size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/logout",
    starred: false,
  },
];
export default managerNavigationConfig;
