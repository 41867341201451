export default class ValidationHelper {
  static required(value) {
    return (value || typeof value === 'number') ? undefined : 'required';
  }
  static isValidEmail(value) {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!value){
      return 'required';
    }else if(!regEmail.test(value)) {
      return 'required';
    }
  }
}
