import React from "react";
import * as Icon from "react-feather";

const ownerNavigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
    starred: false,
  },
  {
    id: "sessions",
    title: "Sessions",
    type: "item",
    icon: <Icon.Key size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/sessions",
    starred: false,
  },
  {
    id: "restaurants",
    title: "Restaurants",
    type: "item",
    icon: <Icon.ShoppingBag size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/locations/all",
  },
  {
    id: "analytics",
    title: "Sales",
    type: "collapse",
    icon: <Icon.BarChart2 size={16} />,
    children: [
      {
        id: "overall-stats",
        title: "Overall Stats",
        type: "item",
        icon: <Icon.Activity size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/overall_stats",
      },
      {
        id: "item-stats",
        title: "Item Stats",
        type: "item",
        icon: <Icon.List size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/item_stats",
      },
    ],
    starred: false,
  },
  {
    id: "Accounts",
    title: "Accounts",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin"],
    navLink: "/staff",
  },
  {
    id: "logout",
    title: "Logout",
    type: "item",
    icon: <Icon.Power size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/logout",
    starred: false,
  },
];
export default ownerNavigationConfig;
