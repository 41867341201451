import React, { Component } from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../helpers/TimeHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Badge } from "reactstrap";
import CONSTANTS from "../../constants";

class ListAllNotification extends Component {
  columns = [
    {
      name: "Message",
      selector: "message",
      format: (val) => {
        return val.message ? val.message : "No message available";
      },
    },
    {
      name: "Session",
      selector: "session_id",
      width: "15%",
      format: (val) => {
        let url = `${CONSTANTS.BASE_URL}sessions/${val.session_id}`;
        if (val.status === "unseen") {
          url += `?n=${val.not_id}`;
        }
        return val.session_id ? <a href={url}>{val.session_id}</a> : "0";
      },
    },
    {
      name: "Status",
      selector: "status",
      width: "7%",
      format: (val) => {
        return val.status && val.status === "seen" ? (
          <Badge pill color="success">
            {val.status}
          </Badge>
        ) : (
          <Badge pill color="danger">
            {val.status}
          </Badge>
        );
      },
    },
    {
      name: "Table",
      selector: "table_name",
      width: "20%",
      format: (val) => {
        return val.table_name ? val.table_name : "Not available";
      },
    },
    {
      name: "Time",
      selector: "not_timer",
      width: "15%",
      format: (val) => {
        return val.not_timer
          ? TimeHelper.toFormattedDateTimeDB(val.not_timer)
          : "Not available";
      },
    },
  ];

  renderNotificationList = (tables) => {
    if (!tables || tables.length === 0)
      return <h1>No App notification found.</h1>;

    return (
      <>
        <DataTable
          noHeader
          paginationRowsPerPageOptions={[25, 50, 100]}
          paginationPerPage={50}
          selectableRowsComponent={Checkbox}
          columns={this.columns}
          data={tables.notificationList}
        />
      </>
    );
  };

  render() {
    return this.renderNotificationList(this.props.notificationData);
  }
}

export default ListAllNotification;
