import React from "react";
import { connect } from "react-redux";
import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  FormGroup,
  Input,
  Media,
} from "reactstrap";
import Select from "react-select";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import { Image, Plus, X } from "react-feather";
import { getLocation } from "../../redux/actions/locations";
import LocationService from "../../services/LocationService";
import TableService from "../../services/TableService";
import { getAll } from "../../redux/actions/options";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import SelectLocation from "../shared/SelectLocation";
import { getConnectTabsTables } from "../../redux/actions/tables";

class SendPopupsOnTabs extends React.Component {
  fileRef = React.createRef();
  state = {
    errorSaving: false,
    selectedTabs: [],
    allAvailableTabsOptions: [],
    confirmationPopup: false,
    errorPopup: false,
    loading: true,
    selectLocation: false,
    titleError: false,
    tabsError: false,
    descriptionError: false,
    notificationTitle: "",
    notificationDescription: "",
    img: "",
  };

  async componentDidMount() {
    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchTables = true) {
    this.setState({ loading: initialize });
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      this.props.getAll(loc_id);
      await this.props.getLocation(loc_id);
    }
    if (fetchTables) {
      await this.getTables(loc_id);
    }

    const availableTabs = [];
    const {
      props: { tables },
    } = this;
    for (let i = 0; i < tables.length; i++) {
      availableTabs.push({
        value: tables[i].tab_device_token,
        label: tables[i].table_name,
      });
    }

    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(this.props.location)),
      allAvailableTabsOptions: availableTabs,
    });
  }

  async getTables(loc_id) {
    return await this.props.getConnectTabsTables(loc_id);
  }

  updateSelectedTabs = (e) => {
    this.setState({ selectedTabs: e });
  };

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  async sendPopupNotification() {
    try {
      const fileInput = document.querySelector("#fileInput");
      const formData = new FormData();
      formData.append("file", fileInput.files[0]);
      formData.append("title", this.state.notificationTitle);
      formData.append("description", this.state.notificationDescription);
      formData.append("selectedtabs", JSON.stringify(this.state.selectedTabs));
      await TableService.sendNotificationToSelectedTabs(
        this.state.location.loc_id,
        formData
      );
      this.setState({
        confirmationPopup: false,
        notificationTitle: "",
        notificationDescription: "",
        selectedTabs: [],
      });
    } catch (e) {
      this.setState({ errorPopup: true });
    }
  }

  checkValidData() {
    if (this.state.selectedTabs.length === 0) {
      this.setState({ tabsError: true });
      return;
    }

    if (
      document.getElementById("title").value === "" ||
      document.getElementById("title").value === null
    ) {
      this.setState({ titleError: true });
      return;
    }

    if (
      document.getElementById("description").value === "" ||
      document.getElementById("description").value === null
    ) {
      this.setState({ descriptionError: true });
      return;
    }
    this.setState({
      confirmationPopup: true,
      notificationTitle: document.getElementById("title").value,
      notificationDescription: document.getElementById("description").value,
    });
  }

  handlePhotoChange(e) {
    if (e.target.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (ev) => {
        this.setState({ img: ev.target.result, deletePhoto: false });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  render() {
    const {
      state: {
        errorSaving,
        allAvailableTabsOptions,
        confirmationPopup,
        errorPopup,
        loading,
        selectLocation,
        titleError,
        descriptionError,
        img,
      },
    } = this;
    const image = img;
    const {
      props: { location },
    } = this;

    if (loading) return <SpinnerComponent />;

    const locationName = location ? location.name : "";
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );
    return (
      <div className="location">
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="d-flex">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.toggleLocation.bind(this)}
            />
          </div>
        </div>
        <Card>
          <CardHeader className="flex-column align-items-start">
            {errorSaving ? (
              <span className="danger">Unable to send popup.</span>
            ) : (
              <h2>
                <strong>Trigger Popups</strong>
              </h2>
            )}
          </CardHeader>
          <CardBody>
            <FormGroup className="row">
              <Col md={12}>
                <Label for="tabs">Select Tables/Tabs:</Label>
                <Select
                  className="React no-pointer-events"
                  classNamePrefix="select"
                  name="tabs"
                  options={allAvailableTabsOptions}
                  onChange={(e) => this.updateSelectedTabs(e)}
                  isMulti
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Col lg={2} md={4} sm={12}>
                <Label for="photoDiv">Image:</Label>
                <div
                  tabIndex="0"
                  id="photoDiv"
                  className="display-flex flex-column border border-2 border-light cursor-pointer"
                  style={{ position: "relative", height: 120 }}
                  onKeyUp={(e) =>
                    e.keyCode === 13 && !e.ctrlKey
                      ? this.fileRef.current.click()
                      : null
                  }
                  onClick={() => this.fileRef.current.click()}
                >
                  <div style={{ textAlign: "-webkit-center" }}>
                    {image ? (
                      <div>
                        <div
                          style={{
                            position: "absolute",
                            top: -10,
                            right: -10,
                          }}
                        >
                          <Button.Ripple
                            onClick={() => {
                              this.setState({ img: null, deletePhoto: true });
                              this.fileRef.current.value = null;
                            }}
                            style={{ width: 20, height: 20 }}
                            className="m-0 p-0"
                            color="danger"
                          >
                            <X size={14} />
                          </Button.Ripple>
                        </div>
                        <div
                          style={{ position: "absolute", bottom: 3 }}
                          className="full-width"
                        >
                          <Button.Ripple
                            className="p-25"
                            color="primary"
                            onClick={() => this.fileRef.current.click()}
                          >
                            <span className="font-small-1">Change Photo</span>
                          </Button.Ripple>
                        </div>
                        <Media
                          object
                          src={image}
                          alt="image"
                          style={{ maxWidth: 148, maxHeight: 118 }}
                          className="align-self-center"
                        />
                      </div>
                    ) : (
                      <div
                        className="align-self-center"
                        style={{ padding: 9, height: 50, width: 50 }}
                      >
                        <Image size={32} className="align-self-center" />
                      </div>
                    )}
                  </div>
                  {image ? null : (
                    <React.Fragment>
                      <span className="font-small-2 primary align-self-center">
                        Click to upload a file
                      </span>
                      <span className="font-small-2 color- align-self-center align-text-center">
                        PNG, JPG, IMG <br /> up to 10mb
                      </span>
                    </React.Fragment>
                  )}
                </div>
                <input
                  type="file"
                  id="fileInput"
                  name="fileInput"
                  style={{ display: "none" }}
                  ref={this.fileRef}
                  onChange={this.handlePhotoChange.bind(this)}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Col md={12}>
                <Label for="title">Popup Title:</Label>
                <Input
                  type="text"
                  id="title"
                  placeholder="Enter Popup title here"
                  invalid={titleError}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Col md={12}>
                <Label for="description">Popup Description:</Label>
                <Input
                  type="textarea"
                  id="description"
                  placeholder="Enter Description"
                  invalid={descriptionError}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Col md={12}>
                <Button
                  color="primary"
                  disabled={false}
                  onClick={(e) => this.checkValidData()}
                >
                  Save
                </Button>{" "}
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
        <ConfirmationModal
          header={"Need Confirmation"}
          title={
            "Are you sure to send popup notification to all selected tables?"
          }
          isOpen={confirmationPopup}
          accept={() => this.sendPopupNotification()}
          reject={() => this.setState({ confirmationPopup: false })}
        />
        <ConfirmationModal
          header={"ERROR"}
          title={"Notification Popups not be able to send. Pls retry again"}
          isOpen={errorPopup}
          reject={() => this.setState({ errorPopup: false })}
          rejectTitle={"OK"}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
    tables: {
      connectedTables: { data: tables, loading: tablesLoading },
    },
  } = state;
  return {
    location: locationData,
    loading: locationLoading && tablesLoading,
    tables,
  };
}

export default connect(mapStateToProps, {
  getLocation,
  getAll,
  getConnectTabsTables,
})(SendPopupsOnTabs);
