import moment from 'moment';
import CONSTANTS from '../constants';

export default class TimeHelper {
  static toFormattedDate(date, format=CONSTANTS.DATES.FORMAT.DISPLAY) {
    if(!date) return '';
    return moment(date).format(format);
  }

  static toFormattedTime(dateTime, format = CONSTANTS.TIMES.FORMAT.DISPLAY) {
    if(!dateTime) return '';
    return moment(dateTime).format(format);
  }

  static getLocalFormattedTime() {
    return moment().local().format(CONSTANTS.TIMES.FORMAT.DISPLAY);
  }

  static toFormattedDateTime(dateTime) {
    if(!dateTime) return '';
    return moment(dateTime).format(`${CONSTANTS.DATE_TIMES.FORMAT.DISPLAY}`)
  }

  static toFormattedDateTimeDB(dateTime) {
    if(!dateTime) return '';
    return moment(dateTime).format(`${CONSTANTS.DATE_TIMES.FORMAT.DISPLAYYEAR}`)
  }

  static getLocalDateTimeFromUTCString(dateTime) {
    return moment.utc(dateTime, CONSTANTS.DATE_TIMES.FORMAT.DB).local();
  }

  static getDaysBeforeToday(days) {
    return moment().subtract(days, 'days').format(CONSTANTS.DATES.FORMAT.DB_DATE);
  }

  static getFirstDayOf2018() {
    return moment('2018-01-01').format(CONSTANTS.DATES.FORMAT.DB_DATE);
  }

  static getFirstDayOf(timespan) {
    return moment().startOf(timespan).format(CONSTANTS.DATES.FORMAT.DB_DATE);
  }

  static getThirtyDaysBeforeToday() {
    return moment().subtract(30, 'days').format(CONSTANTS.DATES.FORMAT.DB_DATE);
  }

  static getCurrentDate() {
    return moment().format(CONSTANTS.DATES.FORMAT.DB_DATE);
  }

  static checkIfTimeIsActive(startTime, endTime) {
    let beginningTime = moment(startTime, "HH:mm");
    let endingTime = moment(endTime, "HH:mm");
    return moment().isBetween(beginningTime, endingTime);
  }

}
