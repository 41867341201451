import CONSTANTS from '../../../constants';
import OptionService from '../../../services/OptionService';

export function save(loc_id, item, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.SAVE,  data: null, loading: true });
      let cat = await OptionService.save(loc_id, item);
      dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.SAVE, data: cat, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.SAVE,  data: null, loading: false });
    }
  };
}

export function deleteOption(loc_id, item, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.DELETE,  data: null, loading: true });
      let cat = await OptionService.del(loc_id, item);
      dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.DELETE, data: cat, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.SAVE,  data: null, loading: false });
    }
  };
}

export function statusOption(loc_id, item, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.STATUS,  data: null, loading: true });
      let cat = await OptionService.status(loc_id, item);
      dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.STATUS, data: cat, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.STATUS,  data: null, loading: false });
    }
  };
}

export function getAll(loc_id, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.GET_ALL,  data: null, loading: true });
      let cat = await OptionService.getAll(loc_id);
      dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.GET_ALL, data: cat, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU_OPTIONS.GET_ALL,  data: null, loading: false });
    }
  };
}