import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { Send } from "react-feather";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import LocationService from "../../services/LocationService";
import { getLocation } from "../../redux/actions/locations";
import SelectLocation from "../shared/SelectLocation";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";

class TermsConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: true,
    };
  }
  async componentDidMount() {
    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchMenu = true) {
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      await this.props.getLocation(loc_id);
    }

    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(this.props.location)),
    });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  updateTermAndConditions = (location) => {
    if (
      document.getElementById("exampleText").value !== "" ||
      document.getElementById("exampleText").value !== null
    ) {
      let data = {'terms_and_conditions' : document.getElementById("exampleText").value};
      LocationService.updateTermAndConditions(
        data,
        location.loc_id
      );
    }
  };

  render() {
    const {
      props: { location },
    } = this;
    const {
      state: { selectLocation, loading },
    } = this;
    const locationName = location ? location.name : "";
    if (loading) return <SpinnerComponent />;
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );
    console.log("location:", location);
    return (
      <div className="location">
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="d-flex">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.toggleLocation.bind(this)}
            />
          </div>
        </div>
        <Row className="m-0 justify-content-center">
          <Col md="12" className="d-flex justify-content-center">
            <Card className="col-12">
              <CardHeader>
                <CardTitle>Terms & Conditions</CardTitle>
              </CardHeader>
              <CardBody>
                <Col md="12">
                  <Input
                    type="textarea"
                    name="text"
                    id="exampleText"
                    rows="15"
                    placeholder="Textarea"
                    defaultValue={location.terms_and_conditions}
                  />
                </Col>
                <Col md="12" className="d-flex justify-content-end mt-2">
                  <Button
                    className="btn-icon"
                    color="primary"
                    onClick={() => this.updateTermAndConditions(location)}
                  >
                    <Send size={15} /> Update Terms & Conditions
                  </Button>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
  } = state;
  return {
    location: locationData,
    loading: locationLoading,
  };
}

export default connect(mapStateToProps, {
  getLocation,
})(TermsConditions);
