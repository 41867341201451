import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  updateMenuPriority,
  saveMenu,
  delMenu,
  updateMenuOptions,
	getDummyItem,
	getModifiers,
  getAllModifiers,
  getAllDishTags,
  updateDishTags,
  deleteDishTags,
  saveDishTag,
  getDishTag,
  getAllergicItems,
  saveAllergicItem,
  updateAllergicItem,
  deleteAllergicItem
};

async function getDummyItem(loc_id) {
	let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_DUMMY_ITEM(loc_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch Dummy Item');
}

async function getModifiers(loc_id, menu_id) {
	let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_MODIFIERS(loc_id, menu_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch Dummy Item');
}

async function getAllModifiers(loc_id) {
	let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_ALL_MODIFIERS(loc_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch Dummy Item');
}
/* Dish tags */
async function getAllDishTags(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_ALL_DISH_TAGS(loc_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch tags');
}


async function getAllergicItems(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_ALL_ALLERGIC_ITEMS(loc_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch tags');
}

async function updateDishTags(loc_id, tag_id , params) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.UPDATE_DISH_TAG(loc_id, tag_id)}`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not update tags');
}

async function updateAllergicItem(loc_id, item_id , params) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.UPDATE_ALLERGIC_ITEM(loc_id, item_id)}`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not update tags');
}

async function deleteAllergicItem(loc_id, item_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DELETE_ALLERGIC_ITEM(loc_id, item_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not delete tags');
}

async function deleteDishTags(loc_id, tag_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DELETE_DISH_TAG(loc_id, tag_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not delete tags');
}

async function saveDishTag(loc_id , item) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.CREATE_DISH_TAG(loc_id)}`, {
    method: 'POST',
    body: JSON.stringify(item),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not save dishtags');
}

async function saveAllergicItem(loc_id , item) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.CREATE_ALLERGIC_ITEM(loc_id)}`, {
    method: 'POST',
    body: JSON.stringify(item),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not save dishtags');
}

async function getDishTag(loc_id , tag_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_DISH_TAG(loc_id, tag_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not delete tags');
}


/* Dish tags */

async function updateMenuPriority(loc_id, menus) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.BULK_UPDATE_PRIORITY(loc_id)}`, {
    method: 'POST',
    body: JSON.stringify(menus),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch Menu Priorities');
}

async function updateMenuOptions(loc_id, menu_id, optionIds) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.MENU_UPDATE_OPTIONS(loc_id, menu_id)}`, {
    method: 'POST',
    body: JSON.stringify(optionIds),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch stats');
}

async function saveMenu(loc_id, item) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DEFAULT(loc_id)}`, {
    method: 'POST',
    body: item,
    headers: { 'Accept': CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not save category');
}

async function delMenu(loc_id, menu) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DEFAULT(loc_id)}/${menu}`, {
    method: 'DELETE',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not delete category');
}