import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getMenusGroupedByCategory,
  updateCategoryPriority,
  saveCategory,
  delCategory,
};

async function getMenusGroupedByCategory(loc_id, minimalData= 0) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.CATEGORY.GET(loc_id)}?minimalData=${minimalData}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not fetch categories');
}

async function updateCategoryPriority(loc_id, categories) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.CATEGORY.BULK_UPDATE_PRIORITY(loc_id)}`, {
    method: 'POST',
    body: JSON.stringify(categories),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not update categories');
}

async function saveCategory(loc_id, category) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.CATEGORY.DEFAULT(loc_id)}`, {
    method: 'POST',
    body: category,
    headers: { 'Accept': CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not save category');
}

async function delCategory(loc_id, category) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.CATEGORY.DEFAULT(loc_id)}/${category}`, {
    method: 'DELETE',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not delete category');
}