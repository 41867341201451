import React from "react";
import * as Icon from "react-feather";

const adminNavigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
    starred: true,
  },
  {
    id: "restaurant-settings",
    title: "Tables",
    type: "item",
    icon: <Icon.Tablet size={20} />,
    permissions: ["admin"],
    navLink: "/tables",
    starred: true,
  },
  {
    id: "settings",
    title: "Settings",
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: "restaurants",
        title: "Restaurants",
        type: "item",
        icon: <Icon.ShoppingBag size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations",
      },
      {
        id: "currencies",
        title: "Currencies",
        type: "item",
        icon: <Icon.DollarSign size={20} />,
        permissions: ["admin"],
        navLink: "/currencies",
      },
    ],
    starred: false,
  },
  {
    id: "analytics",
    title: "Analytics",
    type: "collapse",
    icon: <Icon.BarChart2 size={16} />,
    children: [
      {
        id: "overall-stats",
        title: "Overall Stats",
        type: "item",
        icon: <Icon.Activity size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/overall_stats",
      },
      {
        id: "item-stats",
        title: "Item Stats",
        type: "item",
        icon: <Icon.List size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/item_stats",
      },
    ],
    starred: false,
  },
  {
    id: "customer-list",
    title: "Customer List",
    type: "item",
    icon: <Icon.UserCheck size={20} />,
    permissions: ["admin"],
    navLink: "/customers",
    starred: true,
  },
  {
    id: "accounts",
    title: "Accounts & Roles",
    type: "collapse",
    icon: <Icon.Lock size={20} />,
    children: [
      {
        id: "Accounts",
        title: "Accounts",
        type: "item",
        icon: <Icon.Users size={20} />,
        permissions: ["admin"],
        navLink: "/staff",
      },
      {
        id: "Roles",
        title: "Roles",
        type: "item",
        icon: <Icon.LogIn size={20} />,
        permissions: ["admin"],
        navLink: "/roles",
      },
    ],
    starred: false,
  },
  {
    id: "logout",
    title: "Logout",
    type: "item",
    icon: <Icon.Power size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/logout",
    starred: false,
  },
];
export default adminNavigationConfig;
