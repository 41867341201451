import React from "react";
import { Card, CardHeader, CardTitle, Table, Progress } from "reactstrap";

class RecentLocationForAdmin extends React.Component {
  render() {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Lastest Restaurants</CardTitle>
        </CardHeader>
        <Table
          responsive
          className="dashboard-table table-hover-animation mb-0 mt-1"
        >
          <thead>
            <tr>
              <th>Location</th>
              <th>Category</th>
              <th>Country</th>
              <th>Tax Label</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.length > 0 ? (
              this.props.data.map((item) => {
                return (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.category_name}</td>
                    <td>
                      {item.country}
                    </td>
                    <td>
                      {item.tax_label}
                    </td>
                    <td>{item.is_locally_treat ? 'Local Management' : 'Cloud Management'}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td span="6">No Location Found</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    );
  }
}
export default RecentLocationForAdmin;
