import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { unserialize, serialize } from "php-serialize";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getLocation,
  setLocation,
  getLocations,
  getLocationFromServer,
  updateLocation,
  createLocation,
  getTotalLocationsCount,
  updateLocationFormData,
  checkSlug,
  getAllLocations,
  updateLocationFormData,
  checkSlug,
  getAllLocationsByStaffId,
  setTotalLocationsCount,
  updateTermAndConditions,
  updateCheckoutMessage,
  syncSqlData,
  backupSqlDatabase,
};

async function checkSlug(slug) {
  const { token } = LoginService.getToken();
  const url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.CHECK_SLUG}?name=${slug}`;
  const response = await fetch(url, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  if (response.status !== 200) return false;
  return true;
}

function getLocation(key = `${CONSTANTS.CACHE_KEY_PREFIX}_location`) {
  let location = localStorage.getItem(key);
  if (location) return JSON.parse(location);
  return null;
}

function setLocation(location, key = `${CONSTANTS.CACHE_KEY_PREFIX}_location`) {
  localStorage.setItem(key, JSON.stringify(location));
}

function setTotalLocationsCount(count) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location_count`, count);
}

function getTotalLocationsCount() {
  let count = localStorage.getItem(
    `${CONSTANTS.CACHE_KEY_PREFIX}_location_count`
  );
  if (count === null) return 5;
  return count;
}

async function getLocations(searchTerm) {
  const { token } = LoginService.getToken();
  const url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}?name=${searchTerm}`;
  let response = await fetch(url, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  response = await response.json();
  if (searchTerm === "") {
    setTotalLocationsCount(response.length);
  }
  if (response) return response;

  throw new Error("Could not fetch locations");
}

async function getAllLocations() {
  const { token } = LoginService.getToken();
  const url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.ALL_LOCATIONS}`;
  let response = await fetch(url, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  response = await response.json();

  if (response) return response;

  throw new Error("Could not fetch locations");
}

async function getAllLocationsByStaffId(staffid) {
  const { token } = LoginService.getToken();
  const url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.ALL_LOCATIONS}/${staffid}`;
  let response = await fetch(url, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  response = await response.json();

  if (response) return response;

  throw new Error("Could not fetch locations");
}

async function getLocationFromServer(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}/${locationId}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );

  response = await response.json();
  if (response) {
    response.options = response.options
      ? unserialize(response.options)
      : response.options;
    // if (response.SpecialEvents.length > 0) {
    //   for (let i = 0; i < response.SpecialEvents.length; i++) {
    //     response.SpecialEvents[i].event_timings = response.SpecialEvents[i].event_timings ? await JSON.parse(response.SpecialEvents[i].event_timings) : response.SpecialEvents[i].event_timings;
    //     response.SpecialEvents[i].isServer = 1
    //   }
    // }

    return response;
  }

  throw new Error("Could not fetch location");
}

async function updateLocation(locationData) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}/${locationData.loc_id}`,
    {
      body: JSON.stringify(locationData),
      method: "PATCH",
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
        "Content-Type": "application/json",
      },
    }
  );
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error("Could not save location");
}

async function updateLocationFormData(locationData) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}/${locationData.loc_id}`,
    {
      body: locationData,
      method: "PATCH",
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
      },
    }
  );
  response = await response.json();
  if (response) {
    response.options = response.options
      ? unserialize(response.options)
      : response.options;
    return response;
  }
  throw new Error("Could not save location");
}

async function createLocation(locationData) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}`,
    {
      body: locationData,
      method: "POST",
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
      },
    }
  );
  response = await response.json();
  if (response) {
    response.options = response.options
      ? unserialize(response.options)
      : response.options;
    return response;
  }
  throw new Error("Could not create new location");
}

async function updateTermAndConditions(data, loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.TERMS_AND_CONDITION(
      loc_id
    )}`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error("Could not update");
}

async function updateCheckoutMessage(data, loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.CHECKOUTMESSAGE(loc_id)}`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error("Could not update");
}

async function syncSqlData() {
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.SYNC_DATA}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS},
    }
  );

  response = await response.json();
  return response;
}

async function backupSqlDatabase() {
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.BACKUP_LOCAL_DB}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS},
    }
  );

  response = await response.json();
  return response;
}
