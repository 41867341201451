import React from "react";
import { Button } from "reactstrap";
import { RefreshCcw } from "react-feather";
import LocationService from "../../services/LocationService";
import "../../assets/css/locationSelectHeader.scss";

const RawSqlUploaderHeaders = (props) => {
  return (
    <div>
      <Button
        outline
        className="font-weight-bold success-2-border custom-padding"
        color="success"
        size="md"
        onClick={() => props.onIconClick()}
      >
        <RefreshCcw size={15} />
        {" Sync"}
      </Button>
    </div>
  );
};

export default RawSqlUploaderHeaders;
