import React, {Suspense} from 'react';
import { Route, Redirect } from 'react-router-dom';
import qs from 'query-string';
import LoginService from '../../services/LoginService';
import LocationService from '../../services/LocationService';
import NotificationService from '../../services/NotificationService';
import CONSTANTS from '../../constants';
import { ContextLayout } from '../../helpers/context/Layout';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import ErrorHandler from './ErrorHandler';

const PrivateRoute = ({ component: Component, fullLayout, ...rest }) => (
  <Route {...rest} render={
    (props) => {
      if(props.location.search) {
        let { deviceToken } = qs.parse(props.location.search);
        NotificationService.setTokenInCache(deviceToken);
      }
      if(LoginService.getToken()) {
        LoginService.getLoggedUser();
        if(rest.selectLocation && !LocationService.getLocation()) return <Redirect to={{ pathname: `${CONSTANTS.BASE_URL}select_location`, state: { from: props.location.pathname, data: props.location.state } }} />;
        let pageTitle = rest.pageTitle;
        if( pageTitle === 'Order Details') {
          pageTitle = pageTitle + ' - ' + props.location.pathname.split('/').slice(-1)[0];
        }
        return (
          <ContextLayout.Consumer>
            {context => {
              let LayoutTag =
                fullLayout === true
                  ? context.fullLayout
                  : context.state.activeLayout === 'horizontal'
                  ? context.horizontalLayout
                  : context.verticalLayout;
							const goBackUrl = props.history.location.state && props.history.location.state.goBackUrl ? props.history.location.state.goBackUrl : rest.goBackURL;
              return (
                <LayoutTag {...props} pageTitle={pageTitle} goBackURL={goBackUrl} withConfirmation={rest.withConfirmation}>
                  <Suspense fallback={<SpinnerComponent />}>
										<ErrorHandler history={props.history} >
                    	<Component {...props} />
										</ErrorHandler>
                  </Suspense>
                </LayoutTag>
              )
            }}
          </ContextLayout.Consumer>
        );
      }
      return <Redirect to={{ pathname: `${CONSTANTS.BASE_URL}login`, state: { from: props.location.pathname, data: props.location.state } }} />;
    }
  } />
);

export default PrivateRoute;
