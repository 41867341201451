import CONSTANTS from '../../../constants';
import MenuService from '../../../services/MenuService';
import CategoryService from "../../../services/CategoryService";

export function updateMenuPriority(loc_id, menus, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU.BULK_UPDATE,  data: null, loading: true });
      let menu = await MenuService.updateMenuPriority(loc_id, menus);
      dispatch({type: CONSTANTS.ACTIONS.MENU.BULK_UPDATE, data: menu, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU.BULK_UPDATE,  data: null, loading: false });
    }
  };
}

export function getDummyItem(loc_id, initialize = true) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU.GET_DUMMY_ITEM,  data: null, loading: true });
      let dummyItem = await MenuService.getDummyItem(loc_id);
      dispatch({type: CONSTANTS.ACTIONS.MENU.GET_DUMMY_ITEM, data: dummyItem, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU.GET_DUMMY_ITEM,  data: null, loading: false });
    }
  };
}

export function getAllModifiers(loc_id, initialize = true) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU.GET_ALL_MODIFIERS,  data: null, loading: true });
      let modifier = await MenuService.getAllModifiers(loc_id);
      dispatch({type: CONSTANTS.ACTIONS.MENU.GET_ALL_MODIFIERS, data: modifier, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU.GET_ALL_MODIFIERS,  data: null, loading: false });
    }
  };
}

export function delItem(loc_id, itemId, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU.DELETE,  data: null, loading: true });
      let cat = await MenuService.delMenu(loc_id, itemId);
      dispatch({type: CONSTANTS.ACTIONS.MENU.DELETE, data: cat, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU.DELETE,  data: null, loading: false });
    }
  };
}

export function saveItem(loc_id, item, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU.CREATE,  data: null, loading: true });
      let cat = await MenuService.saveMenu(loc_id, item);
      dispatch({type: CONSTANTS.ACTIONS.MENU.CREATE, data: cat, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU.CREATE,  data: null, loading: false });
    }
  };
}

export function updateMenuOptions(loc_id, menu_id, optionIds, initialize = false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU.CREATE,  data: null, loading: true });
      let cat = await MenuService.updateMenuOptions(loc_id, menu_id, optionIds);
      dispatch({type: CONSTANTS.ACTIONS.MENU.CREATE, data: cat, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU.CREATE,  data: null, loading: false });
    }
  };
}

export function getAllDishTags(loc_id, initialize = true) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU.GET_ALL_DISH_TAGS,  data: null, loading: true });
      let dishtags = await MenuService.getAllDishTags(loc_id);
      dispatch({type: CONSTANTS.ACTIONS.MENU.GET_ALL_DISH_TAGS, data: dishtags, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU.GET_ALL_DISH_TAGS,  data: null, loading: false });
    }
  };
}

export function getAllergicItems(loc_id, initialize = true) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU.GET_ALL_ALLERGIC_ITEMS,  data: null, loading: true });
      let dishtags = await MenuService.getAllergicItems(loc_id);
      dispatch({type: CONSTANTS.ACTIONS.MENU.GET_ALL_ALLERGIC_ITEMS, data: dishtags, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU.GET_ALL_ALLERGIC_ITEMS,  data: null, loading: false });
    }
  };
}

export function saveDishTag(loc_id, item, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU.CREATE_DISH_TAG,  data: null, loading: true });
      let cat = await MenuService.saveDishTag(loc_id, item);
      dispatch({type: CONSTANTS.ACTIONS.MENU.CREATE_DISH_TAG, data: cat, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU.CREATE_DISH_TAG,  data: null, loading: false });
    }
  };
}

export function updateDishTag(loc_id, tag_id, item, initialize = false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU.UPDATE_DISH_TAG,  data: null, loading: true });
      let cat = await MenuService.updateDishTags(loc_id, tag_id, item);
      dispatch({type: CONSTANTS.ACTIONS.MENU.UPDATE_DISH_TAG, data: cat, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU.UPDATE_DISH_TAG,  data: null, loading: false });
    }
  };
}

export function delDishTag(loc_id, itemId, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.MENU.DELETE_DISH_TAG,  data: null, loading: true });
      let cat = await MenuService.deleteDishTags(loc_id, itemId);
      dispatch({type: CONSTANTS.ACTIONS.MENU.DELETE_DISH_TAG, data: cat, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.MENU.DELETE_DISH_TAG,  data: null, loading: false });
    }
  };
}