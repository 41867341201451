import React from "react";
import DataTable from "react-data-table-component";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Check, Edit2 } from "react-feather";
import { Button } from "reactstrap";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import _, { filter } from "lodash";
import { Link } from "react-router-dom";

const ListStaff = (props) => {
  const editStaff = (id) => {
    props.editStaff(id);
  };

  const assignRole = (id) => {
    props.assignRole(id);
  };
  console.log("props", props?.viewDetailbtn);
  let columns = [];
  props?.viewDetailbtn
    ? (columns = [
        {
          name: "Edit",
          selector: "staff_id",
          format: (val) => {
            return (
              <Button
                outline
                className="btn-icon"
                color="primary"
                onClick={() => editStaff(val.staff_id)}
              >
                <Edit2 color="var(--primary)" className="ml-0 p-0" size={10} />
              </Button>
            );
          },
        },

        {
          name: "Name",
          selector: "staff_name",
          minWidth: "200px",
          format: (val) => {
            return val.staff_name ? (
              <Link to={`${'locations/all/'+val.staff_id}`}>{val.staff_name}</Link>
            ) : (
              "Not Available"
            );
          },
        },
        {
          name: "Email",
          selector: "staff_email",
          minWidth: "300px",
          format: (val) => {
            return val.staff_email ? val.staff_email : "Not Available";
          },
        },
        {
          name: "Roles",
          selector: "StaffLocations",
          sortable: true,
          format: (val) => {
            return val.StaffLocations ? val.StaffLocations.length : 0;
          },
        },
        {
          name: "Role Level",
          selector: "StaffLocations",
          minWidth: "200px",
          format: (val) => {
            return val.StaffLocations && val.StaffLocations.length > 0
              ? _.chain(val.StaffLocations)
                  .groupBy("StaffGroup.staff_group_name")
                  .map((value, key) => ({ groups: key, stafflocations: value }))
                  .value()
                  .map((row, key) => {
                    return row.groups && row.groups != "undefined" ? (
                      <>
                        <div className="row">
                          <div className="col-lg-12">
                            <a>{row.groups}</a>
                          </div>
                        </div>
                      </>
                    ) : (
                      "No Role"
                    );
                  })
              : "No Role";
          },
        },
        {
          name: "Date Added",
          selector: "date_added",
          minWidth: "200px",
          sortable: true,
          format: (val) => {
            return val.date_added ? val.date_added : "Not available";
          },
        },
      ])
    : (columns = [
        {
          name: "ID",
          selector: "staff_id",
          sortable: true,
        },
        {
          name: "Edit",
          selector: "staff_id",
          format: (val) => {
            return (
              <Button
                outline
                className="btn-icon"
                color="primary"
                onClick={() => editStaff(val.staff_id)}
              >
                <Edit2 color="var(--primary)" className="ml-0 p-0" size={10} />
              </Button>
            );
          },
        },
        {
          name: "Assign Role",
          selector: "staff_id",
          minWidth: "150px",
          format: (val) => {
            return (
              <Button
                outline
                className="btn-icon"
                color="primary"
                onClick={() => assignRole(val.staff_id)}
              >
                Assign Role
              </Button>
            );
          },
        },
        {
          name: "Name",
          selector: "staff_name",
          minWidth: "200px",
          format: (val) => {
            return val.staff_name ? val.staff_name : "Not Available";
          },
        },
        {
          name: "Email",
          selector: "staff_email",
          minWidth: "300px",
          format: (val) => {
            return val.staff_email ? val.staff_email : "Not Available";
          },
        },
        {
          name: "Roles",
          selector: "StaffLocations",
          sortable: true,
          format: (val) => {
            return val.StaffLocations ? val.StaffLocations.length : 0;
          },
        },
        {
          name: "Role Level",
          selector: "StaffLocations",
          minWidth: "200px",
          format: (val) => {
            return val.StaffLocations && val.StaffLocations.length > 0
              ? _.chain(val.StaffLocations)
                  .groupBy("StaffGroup.staff_group_name")
                  .map((value, key) => ({ groups: key, stafflocations: value }))
                  .value()
                  .map((row, key) => {
                    return row.groups && row.groups != "undefined" ? (
                      <>
                        <div className="row">
                          <div className="col-lg-12">
                            <a>{row.groups}</a>
                          </div>
                        </div>
                      </>
                    ) : (
                      "No Role"
                    );
                  })
              : "No Role";
          },
        },
        {
          name: "Date Added",
          selector: "date_added",
          minWidth: "200px",
          sortable: true,
          format: (val) => {
            return val.date_added ? val.date_added : "Not available";
          },
        },
      ]);

  const renderStaff = (staff, handleChange) => {
    if (!staff || staff.length === 0) return <h1>No App Staff user found.</h1>;

    const tableData = {
      columns: columns,
      data: staff,
      print: false,
      export: false,
    };
    return (
      <DataTableExtensions exportHeaders {...tableData}>
        <DataTable
          noHeader
          pagination
          paginationRowsPerPageOptions={[25, 50, 100]}
          paginationPerPage={50}
          selectableRows={props?.viewDetailbtn ? false : true}
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{
            color: "primary",
            icon: <Check className="vx-icon" size={12} />,
            label: "",
            size: "sm",
          }}
          onSelectedRowsChange={handleChange}
        />
      </DataTableExtensions>
    );
  };
  return renderStaff(props.staff, props.handleChange);
};

export default ListStaff;
