import io from "socket.io-client";
import CONSTANTS from "../constants";
import LoginService from "../services/LoginService";
import LocationService from "../services/LocationService";
class SocketService {
  constructor(url, token, location ) {
    console.log("Hello Socket connection");
    this.url = url;
    this.socket = null;
    this.token = token;
    this.location = location;
    this.tries = 0;
    this.connect();
  }

  connect() {
    this.socket = io(this.url, {
      auth: {
        token: this.token?.token,
        location_id: this.location?.loc_id,
        role: "restaurant_manager",
      },
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionAttempts: 10,
    });

    this.socket.on("connect", () => {
      console.log("Socket connected", this.socket.id);
      this.socket.emit("joinSession");
      this.socket.emit("room");
    });

    this.socket.on("disconnect", () => {
      if (this.tries <= 4) this.socket.connect();
      this.tries++;
      console.log("Socket disconnected");
    });

    this.socket.on("connect_error", (error) => {
      if (this.tries <= 4) this.socket.connect();
      this.tries++;
      console.error("Socket connection error:", error);
    });

    this.socket.on("connect_timeout", (timeout) => {
      if (this.tries <= 4) this.socket.connect();
      this.tries++;
      console.error("Socket connection timeout:", timeout);
    });

    this.socket.on("reconnect", (attemptNumber) => {
      this.tries++;
      console.log(`Socket reconnected after ${attemptNumber} attempts`);
    });

    this.socket.on("reconnect_failed", () => {
      if (this.tries <= 4) this.socket.connect();
      this.tries++;
      console.error("Socket reconnection failed");
    });
  }

  on(eventName, callback) {
    this.socket.on(eventName, callback);
  }

  emit(eventName, data) {
    this.socket.emit(eventName, data);
  }

  removeListener(eventName, callback) {
    this.socket.removeListener(eventName, callback);
  }

  disconnect() {
    this.socket.disconnect();
    this.socket.connect();
  }
}

let token = LoginService.getToken();
let location = LocationService.getLocation();
const SOCKET_URL = CONSTANTS.API_URL;
export default new SocketService(SOCKET_URL, token, location);
