import React, { Component } from 'react';
import {Card, CardBody, CardHeader, Row, Button} from "reactstrap";
export default class ErrorHandler extends Component {
	
	constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
		return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

	componentDidUpdate(prevProps) {
		if(prevProps.children && this.props.children) {
			if(prevProps.children.type.displayName !== this.props.children.type.displayName) {
				this.setState({ hasError: false });
			}
		}
	}

	onRefresh() {
		this.setState({ hasError: false });
		this.props.history.push({path: '/'});
		this.props.history.goBack();
	}

  render() {
		if (this.state.hasError || !this.props.children) {
			return (
				<Row>
					<Card className={'col-md-12'}>
						<CardHeader className='font-large-1'>OOPS! Something Unexpected happened</CardHeader>
						<CardBody className='font-medium-1'>
							Please refresh the page and try again.
							If problem persists please contact us @ developer@tableordercart.app.
							<div className="mt-2">
								<Button color="primary" onClick={this.onRefresh.bind(this)}>
									Reload now
								</Button>
							</div>
						</CardBody>
					</Card>
				</Row>
			);
		}
		return this.props.children;
  }
}
