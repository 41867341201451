import CONSTANTS from '../../../constants';

export function overallStats(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.ANALYTICS.OVERALL) {
    const { data } = action;
    if(data === null)
      return { data: null, loading: action.loading };

    let xAxisLabel = [];
    let totalSales = [];
    let completedOrders = [];
    let cancelledOrders = [];
    for (let i=0; i<data.length; i++) {
      xAxisLabel.push(data[i].x_axis_label);
      totalSales.push(data[i].total_sales);
      completedOrders.push(data[i].total_orders);
      cancelledOrders.push(data[i].cancelled_orders);
    }
    return { data: {
      xAxisLabel,
      totalSales,
      completedOrders,
      cancelledOrders
    }, loading: action.loading};
  }
  return state;
}
