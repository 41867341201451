import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import customizer from './customizer';
import locations from './locations/';
import orders from './orders/';
import configurations from './configurations';
import analytics from './analytics';
import users from './users';
import categories from './categories';
import options from './options';
import currency from './currency';
import tables from './tables';
import staff from './staff';
import roles from './roles';
import menu from './menus';
import notifications from './notifications';

const rootReducer = combineReducers({
    customizer,
    orders,
    configurations,
    locations,
    users,
    analytics,
    categories,
    options,
    currency,
    tables,
    staff,
    roles,
    form,
    menu,
    notifications
});

export default rootReducer;