import React from "react";
import { Card, CardBody, Row, Col, Progress } from "reactstrap";
import Chart from "react-apexcharts";

let topLocationsCount = [];
let topLocationsLabels = [];
class TopCityForLocation extends React.Component {
  state = {
    options: {
      chart: {
        sparkline: { enabled: true },
        toolbar: { show: false },
      },
      states: {
        hover: {
          filter: "none",
        },
      },
      labels: topLocationsLabels,
      colors: [
        "#FF9F43",
        "#00cfe8",
        "#640064",
        "#4a2884",
        "#28C76F",
        "#EA5455",
        "#FFC085",
        "#808000",
        "#3D3C3A",
        "#98AFC7"
      ],
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
          endingShape: "rounded",
        },
      },
      tooltip: {
        x: { show: false },
      },
      xaxis: {
        type: "numeric",
      },
    },
    series: topLocationsCount,
    colors: [
      "primary",
      "success",
      "danger",
      "warning",
      "info",
      "dark",
      "warning_light",
    ],
  };
  componentDidMount() {
    topLocationsCount = [];
    topLocationsLabels = [];
    if (this.props.topLocations.length > 0) {
      this.props.topLocations.map((item) => {
        topLocationsCount.push(item.total_locations);
        topLocationsLabels.push(item.city.toUpperCase());
      });
    }
    this.setState({
      series: topLocationsCount,
      options: { ...this.state.options, labels: topLocationsLabels },
    });
  }

  render() {
    return (
      <Card>
        <CardBody>
          <Row className="pb-50">
            <Col
              lg={{ size: 6, order: 1 }}
              sm={{ size: 12, order: 2 }}
              xs={{ order: 2 }}
              className="d-flex justify-content-between flex-column mt-lg-0 mt-2"
            >
              <div className="session-info">
                <h2 className="text-bold-600 mb-25">
                  {this.props.topLocations.length}
                </h2>
                <p className="text-bold-500 mb-75">Popular Cities</p>
                <h5 className="font-medium-2">
                  <span>{this.props.dataText}</span>
                </h5>
              </div>
            </Col>
            <Col
              lg={{ size: 6, order: 2 }}
              sm={{ size: 12, order: 1 }}
              xs={{ order: 1 }}
              className="d-flex justify-content-between flex-column text-right"
            >
              <Chart
                options={this.state.options}
                series={this.state.series}
                type="donut"
                height={200}
              />
            </Col>
          </Row>
          <hr />
          {this.props.topLocations.length > 0 ? (
            <Row className="pt-50">
              {this.props.topLocations.map((item, i) => {
                return (
                  <Col md="6" sm="12">
                    <p className="mb-0">
                      {item.city.toUpperCase()}: {item.total_locations}
                    </p>
                    <Progress
                      className="mt-25"
                      value="50"
                      // color={this.state.colors[i]}
                      style={{backgroundColor: this.state.options.colors[i]}}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : null}
        </CardBody>
      </Card>
    );
  }
}
export default TopCityForLocation;
