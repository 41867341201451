import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getLiveOrders,
  getOrder,
  updateStatus,
  getSessionsList,
  getSessionsDetail,
  closeLocationSession,
  deleteLocationSession
};

async function getLiveOrders(params) {
  let { token } = LoginService.getToken();
  // console.log(fetch);
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.LIVEORDER}`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not get live orders: ${response}`);
}

async function getOrder(locationId, orderId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.DEFAULT(
      locationId
    )}/${orderId}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not get live order: ${response}`);
}

async function updateStatus(locationId, orderId, statusId, reasonToCancel) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.DEFAULT(
      locationId
    )}/${orderId}`,
    {
      method: "PUT",
      body: JSON.stringify({
        status_id: statusId,
        status_history: { comment: reasonToCancel },
      }),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );

  response = await response.json();
  if (response) return response;
  throw new Error(`Could not update order`);
}

async function getSessionsList(params) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.SESSIONS_LIST(
      params.loc_id
    )}?page=${params?.page ? params.page : 0}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not get live order: ${response}`);
}

async function getSessionsDetail(id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.SESSIONS_DETAIL(id)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not get live order: ${response}`);
}

async function closeLocationSession(session_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.CLOSE_SESSION(session_id)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error("Could not update");
}


async function deleteLocationSession(session_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DELETE_SESSION(session_id)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error("Could not update");
}
