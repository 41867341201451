import CONSTANTS from '../../../constants';
import OrderService from '../../../services/OrderService';

export function getSessionsList(params, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.ORDERS.SESSIONS_LIST,  orders: null, loading: true });
      let orders = await OrderService.getSessionsList(params);
      dispatch({type: CONSTANTS.ACTIONS.ORDERS.SESSIONS_LIST,  orders: orders, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.ORDERS.SESSIONS_LIST,  orders: null, loading: false });
    }
  };
}

// export function delSession(session_id, initialize= false) {
//   return async dispatch => {
//     try {
//       if(initialize) dispatch({type: CONSTANTS.ACTIONS.ORDERS.SESSIONS_LIST,  orders: null, loading: true });
//       let cat = await OrderService.closeLocationSession(session_id);
//       dispatch({type: CONSTANTS.ACTIONS.ORDERS.SESSIONS_LIST, orders: cat, loading: false });
//     }
//     catch (error) {
//       console.log(error);
//       dispatch({type: CONSTANTS.ACTIONS.ORDERS.SESSIONS_LIST,  orders: null, loading: false });
//     }
//   };
// }

export function delSession(session_id, initialize= false) {
    return async dispatch => {
      try {
        if(initialize) dispatch({type: CONSTANTS.ACTIONS.ORDERS.SESSIONS_LIST,  orders: null, loading: true });
        let cat = await OrderService.deleteLocationSession(session_id);
        dispatch({type: CONSTANTS.ACTIONS.ORDERS.SESSIONS_LIST, orders: cat, loading: false });
      }
      catch (error) {
        console.log(error);
        dispatch({type: CONSTANTS.ACTIONS.ORDERS.SESSIONS_LIST,  orders: null, loading: false });
      }
    };
  }
