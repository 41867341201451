import CONSTANTS from '../../../constants';

export function users(state = { data: null, loading: true }, action) {
  return state;
}
export function allusers(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.USERS.ALL_USERS) return { data: action.users, loading: action.loading };
  return state;
}
export function allreports(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.USERS.ALL_REPORTS) return { data: action.reports, loading: action.loading };
  return state;
}