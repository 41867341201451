import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Printer } from "react-feather";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import OrderService from "../../services/OrderService";
import ViewHelper from "../../helpers/ViewHelper";
import TimeHelper from "../../helpers/TimeHelper";
import CONSTANTS from "../../constants";
import "../../assets/css/orders.scss";
class PrintOrderSession extends Component {
  state = {
    loading: true,
    orderSessionDetail: null,
    orderMenuTax: null,
  };
  async componentDidMount() {
    let sessionId = this.props.sessionId;
    let sessionDetail = await OrderService.getSessionsDetail(sessionId);
    this.setState({
      orderSessionDetail: sessionDetail.sessionInfo,
      loading: false,
      orderMenuTax: sessionDetail.order_menu_tax,
    });
    console.log(sessionDetail);
  }

  renderOrderDetailViewTop(session) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        id="order-heading"
      >
        <div className="text-uppercase">
          <p>Session detail</p>
        </div>
        <div className="h4">
          {TimeHelper.toFormattedDate(session.session_date, "ddd,Do MMM, YYYY")}
        </div>
        <div className="pt-1">
          Start time: {session.start_time} End time: {session.end_time}
        </div>
        <div className="pt-1">
          <p>Session #{session.session_id} </p>
        </div>
      </div>
    );
  }

  renderMenuDetails(order, orderMenuTax) {
    // const orderTotals = order.OrderTotals;
    // let startIndex = 0;
    // let subTotal = orderTotals[startIndex++].value;
    let discounts = 0;
    // if (orderTotals.length > 3) {
    //   discounts = orderTotals[startIndex++].value;
    //   subTotal += parseInt(discounts);
    // }

    // const tax = orderTotals[startIndex++].value;
    // const totals = orderTotals[startIndex].value;
    let subTotal = 0;
    let tax = 0;
    let totals = 0;
    return (
      <Card style={{ margin: "0px !important" , padding: "0px !important" }} className="w-100">
        <CardHeader>Order Items</CardHeader>
        <CardBody>
          {order.Orders.map((singleorder) => {
            const orderTotals = singleorder.OrderTotals;
            let startIndex = 0;
            subTotal += orderTotals[startIndex++].value;
            if (orderTotals.length > 3) {
              discounts = orderTotals[startIndex++].value;
              subTotal += parseInt(discounts);
            }
            tax += orderTotals[startIndex++].value;
            totals += orderTotals[startIndex].value;
            return singleorder.OrderMenus.map((menu) => {
              return [
                <div
                  className="order-item mb-1 d-flex justify-content-between"
                  key={menu.order_menu_id}
                >
                  <div className="f2 name pr-1">
                    <b>
                      {menu.quantity}x {menu.name}
                    </b>
                    {this.renderOptions(singleorder, menu.order_menu_id)}
                  </div>
                  <div className="f2 price">
                    <strong>
                      {ViewHelper.toCurrency(
                        parseInt(menu.quantity) * menu.price,
                        order.Location.Currency.currency_code,
                        order.Location.decimal_places
                      )}
                    </strong>
                  </div>
                </div>,
                <div
                  className="order-item mb-3 d-flex justify-content-between"
                  key="comment"
                >
                  {menu.comment && (
                    <div className="f3 name pr-1">
                      Special Instructions:{" "}
                      <span className="font-italic">
                        {menu.comment}
                      </span>
                    </div>
                  )}
                </div>,
              ];
            });
          })}
          <div className="order-item border-top pt-2 d-flex justify-content-between">
            <div className="f2 name pr-1">Sub Total</div>
            <div className="f2 price">
              {ViewHelper.toCurrency(
                subTotal,
                order.Location.Currency.currency_code,
                order.Location.decimal_places
              )}
            </div>
          </div>

          {/* <div className="order-item d-flex justify-content-between">
                <div className="f2 name pr-1">Discounts</div>
                <div className="f2 price">
                  {ViewHelper.toCurrency(
                    -discounts,
                    order.Location.Currency.currency_code,
                    order.Location.decimal_places
                  )}
                </div>
              </div> */}
          {orderMenuTax.length > 0
            ? orderMenuTax.map((menuTax) => {
                return (
                  <div className="order-item d-flex justify-content-between">
                    <div className="f2 name pr-1">
                      {order.Location.tax_label} {menuTax.menu_tax_percentage}%
                    </div>
                    <div className="f2 price">
                      {ViewHelper.toCurrency(
                        menuTax.total_menu_tax,
                        order.Location.Currency.currency_code,
                        order.Location.decimal_places
                      )}
                    </div>
                  </div>
                );
              })
            : null}

          <div className="order-item d-flex justify-content-between border-bottom pb-2">
            <div className="f2 name pr-1">{order.Location.tax_label} Total</div>
            <div className="f2 price">
              {ViewHelper.toCurrency(
                tax,
                order.Location.Currency.currency_code,
                order.Location.decimal_places
              )}
            </div>
          </div>

          <div className="order-item d-flex justify-content-between pt-1">
            <div className="f2 name pr-1">
              <strong>Total</strong>
            </div>
            <div className="f2 price">
              <strong>
                {ViewHelper.toCurrency(
                  totals,
                  order.Location.Currency.currency_code,
                  order.Location.decimal_places
                )}
              </strong>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  renderOptions(order, orderMenuId) {
    let options = order.OrderOptions.filter(
      (option) => option.order_menu_id === orderMenuId
    );
    return options.map((option) => {
      return (
        <div
          key={option.order_option_id}
          className="text-small pl-1"
        >
          {option.display_type ===
          CONSTANTS.VARIABLES.MENUS.OPTIONS.DISPLAY_TYPES.SELECT
            ? `${option.quantity}x `
            : ""}
          {option.order_option_name}{" "}
          {ViewHelper.toCurrency(
            option.order_option_price,
            this.state.orderSessionDetail.Location.Currency.currency_code,
            this.state.orderSessionDetail.Location.decimal_places
          )}
        </div>
      );
    });
  }

  renderOrderDetails(order) {
    return (
      <Card style={{ margin: "0px !important" , padding: "0px !important" }} className="w-100">
        <CardHeader>Customer & Order Detail</CardHeader>
        <CardBody>
          <div className="mb-2 order-item mb-1 d-flex justify-content-between">
            <div>
              <strong>Table Name</strong>
            </div>
            <div>{ViewHelper.getTableName(order.Table)}</div>
          </div>
          <div className="mb-2 order-item mb-1 d-flex justify-content-between">
            <div>
              <strong>Session Status</strong>
            </div>
            <div>{order.session_status.toUpperCase()}</div>
          </div>
          <div className="mb-2 order-item mb-1 d-flex justify-content-between">
            <div>
              <strong>Location Name</strong>
            </div>
            <div>{order.Location.name}</div>
          </div>
        </CardBody>
      </Card>
    );
  }

  render() {
    const {
      state: { orderSessionDetail, loading, orderMenuTax },
    } = this;

    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div className="print_body">
          {/* <BreadCrumbs
              breadCrumbTitle="Session Order Detail"
              breadCrumbParent="Session"
              breadCrumbActive="Session detail"
            /> */}
          {this.renderOrderDetailViewTop(orderSessionDetail)}
          <Row>
            <Col md="12">
              {this.renderMenuDetails(orderSessionDetail, orderMenuTax)}
            </Col>
            <Col md="12">
              {this.renderOrderDetails(orderSessionDetail)}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PrintOrderSession;
