import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import TimeHelper from "../../helpers/TimeHelper";
import ViewHelper from "../../helpers/ViewHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Check, Edit2 } from "react-feather";
import { Button } from "reactstrap";
import ImageHelper from "../../helpers/ImageHelper";


const ListDishTags = (props) => {

  const editDishTag = (id) => {
    props.editDishTag(id);
  }

  const columns = [
    {
      name: 'ID',
      selector: 'tag_id',
      sortable: true,
    }, {
      name: 'Edit',
      selector: 'tag_id',
      format: (val) => {
        return <Button outline className='btn-icon' color='primary' onClick={() => editDishTag(val.tag_id)}><Edit2 color='var(--primary)' className='ml-0 p-0' size={10} /></Button>

      }
    }, 
    {
      name: 'Tag Name',
      selector: 'tag_name',
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return  val.tag_name ? val.tag_name : 'Not Available';
      }
    }, {
      name: 'Description',
      selector: 'tag_description',
      minWidth: '300px',
      format: (val) => {
        return val.tag_description ? val.tag_description : 'Not Available';
      }
    }
  ];

  const renderDishTags = (dishtags, handleChange) => {
    if (!dishtags || dishtags.length === 0)
      return <h1>No App dishtags found.</h1>;
    const tableData = {
      columns: columns,
      data: dishtags,
    };
    return <DataTableExtensions
      {...tableData}
    ><DataTable
        noHeader
        pagination
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationPerPage={50}
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={{
          color: "primary",
          icon: <Check className="vx-icon" size={12} />,
          label: "",
          size: "sm"
        }}
        onSelectedRowsChange={handleChange}
        columns={columns}
        data={dishtags}
      /></DataTableExtensions>;
  }
  return renderDishTags(props.dishtags, props.handleChange);

}

export default ListDishTags;