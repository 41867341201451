import CONSTANTS from '../../../constants';

export function options(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.MENU_OPTIONS.GET_ALL) {
    return { data: action.data, loading: action.loading};
  }
  return state;
}

export function option(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.MENU_OPTIONS.SAVE) {
    return { data: action.data, loading: action.loading};
  }
  return state;
}