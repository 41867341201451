import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Table, Button } from "reactstrap";
import { getLocation } from "../../redux/actions/locations";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { getSessionsList, delSession } from "../../redux/actions/ordersessions";
import LocationService from "../../services/LocationService";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import Pagination from "../shared/Pagination";
import ViewHelper from "../../helpers/ViewHelper";
import { Link } from "react-router-dom";
import "../../assets/css/orders.scss";
import CONSTANTS from "../../constants";
import { X } from "react-feather";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
class OrderSessions extends Component {
  state = {
    selectLocation: false,
    location: null,
    loading: true,
    isOutlet: false,
    page: 0,
    confirmationDialog: {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "Are you sure to close this session?",
      header: "Close Session",
    },
  };
  async componentDidMount() {
    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchSesionsOrder = true) {
    this.setState({ loading: initialize, refreshing: true });
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      await this.props.getLocation(loc_id);
    }

    if (fetchSesionsOrder) {
      await this.props.getSessionsList({ loc_id });
    }

    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(this.props.location)),
      isOutlet: this.props.location.is_outlet,
    });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  closeLocationSession = (session) => {
    this.showConfirmationDialog(
      async () => {
        await this.props.delSession(session.session_id);
      },
      null,
      "Are you sure you want to delete this session?",
      "Delete Session"
    );
  };

  renderOrdersSession(sessionList) {
    if (sessionList.length === 0)
      return <div className="p-3 border-top-1">No session order found.</div>;
    return (
      <Card>
        <Table
          responsive
          className="dashboard-table table-hover-animation mb-0 mt-1"
        >
          <thead>
            <tr>
              <th>Session</th>
              <th>Table</th>
              <th>Order Amount</th>
              <th>Session Date</th>
              <th>Start time</th>
              <th>End time</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          {
            <tbody>
              {sessionList.map((session) => this.renderOrderSession(session))}
            </tbody>
          }
        </Table>
      </Card>
    );
  }

  renderOrderSession(session) {
    console.log("session", session);
    return (
      <tr key={session.session_id}>
        <td id="item_parent">
          <div className="f2 mb-1 mr-1">
            <Link
              className="btn"
              onClick={this.goToOrderSession.bind(this, session.session_id)}
            >
              # {session.session_id}
            </Link>
          </div>
        </td>
        <td>{session.table_name}</td>
        <td className="p-1">
          {ViewHelper.toCurrency(
            session.session_order_amount,
            this.props.location.Currency.currency_code
          )}
        </td>
        <td>{session.session_date}</td>
        <td>{session.start_time}</td>
        <td>{session.end_time}</td>
        {/* <td>
          {session.session_status === "completed" ? (
            <Button
              className="btn-icon"
              color="primary"
              onClick={() => this.closeLocationSession(session)}
            >
              <X size={15} />
            </Button>
          ) : null}
        </td> */}
      </tr>
    );
  }

  goToOrderSession(sessionid) {
    this.props.history.push(`${CONSTANTS.BASE_URL}sessions/${sessionid}`);
  }

  getOrdersSession(page) {
    let params = { page, loc_id: this.state.location.loc_id };
    this.props.getSessionsList(params);
  }

  onPageChange(page) {
    this.setState({ page: page.selected }, () => {
      this.getOrdersSession(page.selected);
    });
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  render() {
    const {
      props: { location, sessionslist, totalCount },
    } = this;
    const {
      state: { selectLocation, loading },
    } = this;
    const {
      state: { confirmationDialog },
    } = this;
    if (loading) return <SpinnerComponent />;
    const locationName = location ? location.name : "";
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      <div className="location">
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="d-flex">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.toggleLocation.bind(this)}
            />
          </div>
        </div>
        {this.renderOrdersSession(sessionslist)}
        {sessionslist.length > 0 && (
          <Pagination
            onPageChange={this.onPageChange.bind(this)}
            page={this.state.page}
            count={totalCount}
          />
        )}
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={"Cancel"}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sessionslist } = state.orders;
  const { location } = state.locations;
  return {
    sessionslist: sessionslist?.data?.data ? sessionslist?.data?.data : [],
    totalCount: sessionslist?.data?.count ? sessionslist?.data?.count : 0,
    loading: sessionslist.loading || location.loading,
    location: location.data,
  };
}

export default connect(mapStateToProps, {
  getSessionsList,
  getLocation,
  delSession,
})(OrderSessions);
