export default class ParamHelper {
  static getOrderId(props) {
    return props.match.params.order_id;
  }

  static getLocationId(props) {
    return props.match.params.location_id;
  }
  static getPlanId(props) {
    return props.match.params.id;
  }
  static getCategoriesForBulkUpdate(categories) {
    return categories.map( i => ({ category_id: i.category_id, priority: i.priority, name: '', description: '', parent_id: 0, image: '', }));
  }

  static getMenusForBulkUpdate(menus) {
    console.log("menus: " , menus)
    return menus.map( i => ({ menu_id: i.menu_id, menu_priority: i.menu_priority, menu_name: '', menu_description: '', menu_price: 0, menu_photo: '', menu_category_id: 0, stock_qty: 0, minimum_qty: 0, subtract_stock: 0, mealtime_id: 0 , loc_id: i.loc_id }));
  }
}
