import React from "react";
import { NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AutoComplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
// import { history } from "../../../history"
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// a little function to help us with reordering the bookmarks
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class NavbarBookmarks extends React.PureComponent {
  state = {
    showBookmarks: false,
    value: "",
    noSuggestions: false,
    isStarred: false,
    suggestions: [],
    starredItems: [],
  };

  updateBookmarks = false;

  handleBookmarksVisibility = () => {
    this.setState({
      showBookmarks: !this.state.showBookmarks,
      value: "",
      suggestions: [],
      noSuggestions: false,
      starred: null,
    });
  };

  componentDidMount() {}

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const starredItems = reorder(
      this.state.starredItems,
      result.source.index,
      result.destination.index
    );

    this.setState({
      starredItems,
    });
  };

  renderBookmarks = (navigationConfig) => {
    this.updateBookmarks = true;
    return (
      <div className="d-flex flex-sm-wrap flex-lg-nowrap draggable-cards">
        {navigationConfig.map((item, index) => {
          if (item.starred) {
            return (
              <div key={item.id}>
                <NavItem className="nav-item d-none d-lg-block">
                  <a
                    id={item.id}
                    className="nav-link cursor-pointer"
                    href={item.navLink}
                  >
                    {item.icon} {item.title}
                  </a>
                </NavItem>
              </div>
            );
          } else if (!item.starred && item.type === "collapse") {
            return item.children.map((children) => {
              if (children.starred)
                return (
                  <div key={children.id}>
                    <NavItem className="nav-item d-none d-lg-block">
                      <a
                        id={children.id}
                        className="nav-link cursor-pointer"
                        href={children.navLink}
                      >
                        {children.icon} {children.title}
                      </a>
                    </NavItem>
                  </div>
                );
            });
          }
        })}
      </div>
    );
  };

  render() {
    let { sidebarVisibility, navigationConfig } = this.props;
    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={sidebarVisibility}
            >
              <Icon.Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        <ul className="nav navbar-nav bookmark-icons">
          {this.renderBookmarks(navigationConfig)}
          <NavItem
            className="nav-item d-none d-lg-block"
            onClick={this.handleBookmarksVisibility}
          ></NavItem>
        </ul>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    bookmarks: state.navbar,
  };
};

export default connect(mapStateToProps)(NavbarBookmarks);
