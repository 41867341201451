import CONSTANTS from "../constants";
import { customFetch as fetch } from "../helpers/FetchHelper";
import LocationService from "./LocationService";

export default {
  getToken,
  setLoginData,
  removeLoginData,
  login,
  setSmsWallet,
  getSmsWallet,
  getRole,
  getLoggedUser,
  setRole,
  getLoggedInAuthData,
};

function setLoginData(tokenData, role) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`, tokenData.token);
  let roles = "No Role";
  // role.map((item , key) => {

  //   roles.push(item.StaffGroup.staff_group_name);
  // });
  roles = role.length > 0 ? role[0].StaffGroup.staff_group_name : "No Role";
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`, roles);
  localStorage.setItem(
    `${CONSTANTS.CACHE_KEY_PREFIX}_auth`,
    JSON.stringify(tokenData.user)
  );
  localStorage.setItem(`DashColor`, "light");
  if (tokenData.locations !== null) {
    LocationService.setLocation(tokenData.locations);
    LocationService.setLocation(tokenData.locations , CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    LocationService.setTotalLocationsCount(tokenData.locations.length);
  }
  this.setSmsWallet(tokenData.sms_wallet);
}

function setRole(role) {
  let roles = [];
  // role.map((item) => {
  //   roles.push(item.StaffGroup.staff_group_name);
  // });
  roles = role
    ? role.length > 0
      ? role[0].StaffGroup.staff_group_name
      : "No Role"
    : "No Role";
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`, roles);
}

function setSmsWallet(wallet) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_sms_wallet`, wallet);
}

function removeLoginData() {
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location_count`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_auth`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_socketConnection`);
}

function getToken() {
  let token = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  if (token) return { token };
  return null;
}

function getRole() {
  let role = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`);
  if (role) return { role };
  return null;
}

function getSmsWallet() {
  let wallet = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_sms_wallet`);
  if (wallet) return { wallet };
  return null;
}

async function login(email, password) {
  let data = { email, password };
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOGIN}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: CONSTANTS.REQUEST_HEADERS,
  });
  response = await response.json();
  if (response && response.token)
    return {
      token: response.token,
      sms_wallet: response.sms_wallet,
      role: response.role,
      user: response.user,
      locations: response.locations,
    };
  throw new Error(`Could not login: ${response}`);
}

async function getLoggedUser() {
  let { token } = this.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}/session_user`, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  response = await response.json();
  this.setRole(response.role);
}

function getLoggedInAuthData() {
  let authdata = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_auth`);
  if (authdata) return JSON.parse(authdata);
  return null;
}
