import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getBranding,
  saveBranding,
};

async function getBranding(location_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.BRANDING.DEFAULT(location_id)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch users");
}

async function saveBranding(location_id, branding) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.BRANDING.DEFAULT(
      location_id
    )}`,
    {
      method: "POST",
      body: branding,
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
      },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update bann users");
}

