import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoginService from '../../services/LoginService';
import CONSTANTS from '../../constants';

export default class Logout extends Component {
  componentDidMount() {
    LoginService.removeLoginData();
    window.location.href = "/";
  }

  render (){
    return <Redirect to={CONSTANTS.BASE_URL} />
  }
}
