import CONSTANTS from '../../../constants';

export function allstaff(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.STAFF.ALL_STAFF) return { data: action.staff, loading: action.loading };
  return state;
}
export function staff(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.STAFF.DEFAULT) return { data: action.staff, loading: action.loading };
  return state;
}
export function allroles(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.STAFF.ALL_ROLES) return { data: action.roles, loading: action.loading };
  return state;
}