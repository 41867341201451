import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getAllRoles,
  createRole,
  getRole,
  changeRoleStatus,
};

async function getAllRoles(term) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ROLES.DEFAULT}?name=${term}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch roles');
}

async function getRole(roleId) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ROLES.GET_ROLE(roleId)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch role');
}

async function createRole(role) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ROLES.DEFAULT}`, {
    method: 'POST',
    body:JSON.stringify(role),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not fetch roles');
}

async function changeRoleStatus(role) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ROLES.STATUSES}`, {
    method: 'POST',
    body: JSON.stringify(role),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not update roles');
}